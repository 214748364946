import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  deleteCoursesAPI,
  editCourseAPI,
} from "../../../API/admin/AdminAPI";
import { Modal, Box, Button, TextField, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"; // Importing Material UI components

const CoursesTableRow = ({ course, onUpdateCourse,fetchCourse}) => {
  const [openModal, setOpenModal] = useState(false); // State to control modal visibility
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for delete confirmation dialog
  const [editCourseData, setEditCourseData] = useState({
    course: course.course,
    duration: course.duration,
    fees: course.fees,
    applicationFees: course.applicationFees,
    courseType: course.courseType || "", // new field
    requirementDescription: course.requirementDescription || "", // new field
  });

  // Open and close modal handlers
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Open and close delete dialog handlers
  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  // Handle form field changes
  const handleChange = (e) => {
    setEditCourseData({ ...editCourseData, [e.target.name]: e.target.value });
  };

  // Handle Edit Course Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await editCourseAPI(editCourseData, course._id);
      if (response.status === 200) {
        handleCloseModal();
        onUpdateCourse(response.data.course);
        alert("Successfully updated");
        
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle course deletion
  const handleDeleteCourse = async () => {
    try {
      const response = await deleteCoursesAPI(course._id);
      if (response.status === 200) {
        // Handle successful deletion
        alert("Course deleted successfully");
        fetchCourse()
      }
      handleCloseDeleteDialog(); // Close dialog after deletion
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <tr key={course?._id}>
        <td className="border border-1 border-solid text-left text-sm p-[8px] capitalize hover:underline">
          {course.course}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {course.duration}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {course.fees}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {course.applicationFees}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          <ol>
            {course?.requirementDescription
              ?.split("\n")
              .filter((requirement) => requirement.trim() !== "") // Filter out empty lines
              .map((requirement, index) => (
                <li key={index} className="flex gap-2">
                  <span>{index + 1}.</span>
                  <p>{requirement.trim()}</p>
                </li>
              ))}
          </ol>
        </td>
        <td className="text-[30px] text-gray-500 text-center border border-1 border-solid p-[8px]">
          <button
            onClick={handleOpenModal}
            className="bg-blue-300 hover:bg-blue-400 text-black font-bold py-2 px-2 rounded inline-flex items-center"
          >
            <EditIcon />
          </button>
          <button
            onClick={handleOpenDeleteDialog} // Show delete confirmation dialog
            className="ml-2 bg-red-300 hover:bg-red-400 text-white font-bold py-2 px-2 rounded inline-flex items-center"
          >
            <DeleteIcon />
          </button>
        </td>
      </tr>

      {/* Modal for editing the course */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            borderRadius: "20px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2>Edit Course</h2>
          <TextField
            label="Course Name"
            name="course"
            value={editCourseData.course}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Duration"
            name="duration"
            value={editCourseData.duration}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Fees"
            name="fees"
            type="number"
            value={editCourseData.fees}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Application Fees"
            name="applicationFees"
            type="number"
            value={editCourseData.applicationFees}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          {/* Add new courseType field */}
          <TextField
            select
            label="Course Type"
            name="courseType"
            value={editCourseData.courseType}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value="">Select Course Type</MenuItem>
            <MenuItem value="diploma">Diploma</MenuItem>
            <MenuItem value="higher_diploma">Higher Diploma</MenuItem>
            <MenuItem value="post_graduation">Post Graduation</MenuItem>
          </TextField>

          {/* Add new requirementDescription field */}
          <TextField
            label="Requirement Description"
            name="requirementDescription"
            value={editCourseData.requirementDescription}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />

          <Box sx={{ mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ borderRadius: "20px" }}
              fullWidth
            >
              Update Course
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Dialog for Deletion */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this course? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteCourse} color="secondary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CoursesTableRow;
