import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../components/AdminComponents/Login/Login";
import Dashboard from "../../pages/AdminPages/Dashboard/Dashboard";
import Colleges from "../../pages/AdminPages/Colleges/Colleges";
import Payments from "../../pages/AdminPages/Payments/Payments";
import Admins from "../../pages/AdminPages/Admins/Admins";
import CollegeProfile from "../../pages/AdminPages/CollegeProfile/CollegeProfile";
import Profile from "../../pages/AdminPages/Profile/Profile";
import Home from "../../components/AdminComponents/Home/Home";
import Representatives from "../../pages/AdminPages/Representative/Representatives";
import Agents from "../../pages/AdminPages/Agents/Agents";
import RepProfile from "../../pages/AdminPages/Profile/RepProfile";
import AgentProfile from "../../pages/AdminPages/Profile/AgentProfile";
import StudePro from "../../pages/AdminPages/Profile/StudePro";
import SettlePayPage from "../../pages/AdminPages/Payments/SettlePayPage";
import Reports from "../../pages/AdminPages/Reports/Reports";
import ProtectedRoute from "../../components/AdminComponents/ProtectedRoute/ProtectedRoute";
import Page404 from "../../components/AdminComponents/404/404-page";
import PaymentLogPage from "../../pages/AdminPages/PaymentLogsPage/PaymentLogPage";
import AdminProfiles from "../../components/AdminComponents/Profiles/AdminProfiles";
import AgentRecruitPage from "../../pages/AdminPages/AgentRecruites/AgentRecruitPage";
import AgentSingleProfile from "../../pages/AdminPages/AgentRecruites/AgentSingleProfile";
import OfferLetter from "../../pages/Pdf Templates/offerLetter";
import StudentPreContract from "../../pages/Pdf Templates/StudentPreContract";
import AdvisoryNote from "../../pages/Pdf Templates/AdvisoryNote";
import Invoice from "../../pages/Pdf Templates/invoice";
import LetterOfOffer from "../../pages/Pdf Templates/LetterOfOffer";
import CourseDetails from "../../pages/AdminPages/Courses/CourseDetails";
import ApplicationFee from "../../pages/Pdf Templates/ApplicationFee";


const Admin = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
          exact
        />
        <Route path="/login" element={<Login />} exact />
        <Route
          path="/colleges"
          element={
            <ProtectedRoute>
              <Colleges />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/payments"
          element={
            <ProtectedRoute>
              <Payments />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/admins"
          element={
            <ProtectedRoute>
              <Admins />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/reports"
          element={
            <ProtectedRoute>
              <Reports />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/representatives"
          element={
            <ProtectedRoute>
              <Representatives />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/agents"
          element={
            <ProtectedRoute>
              <Agents />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/colleges/:college_name"
          element={
            <ProtectedRoute>
              <CollegeProfile />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/college-course/:course_name"
          element={
            <ProtectedRoute>
              <CourseDetails />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/representatives/:rep_name"
          element={
            <ProtectedRoute>
              <RepProfile />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/agents/:agent_name"
          element={
            <ProtectedRoute>
              <AgentProfile />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/student/:student_name"
          element={
            <ProtectedRoute>
              <StudePro />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/admin-profile/:admin_name"
          element={
            <ProtectedRoute>
              <AdminProfiles />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/settle-payment/:paymentId"
          element={<SettlePayPage />}
          exact
        />
        <Route
          path="/payment-history/"
          element={
            <ProtectedRoute>
              <PaymentLogPage />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/agent-applications/"
          element={
            <ProtectedRoute>
              <AgentRecruitPage />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/agent-applications/:applicantName"
          element={
            <ProtectedRoute>
              <AgentSingleProfile />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/student-offer-letter/:studentId"
          element={
            <ProtectedRoute>
              <OfferLetter />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/letter-of-offer-student/:studentId"
          element={
            <ProtectedRoute>
              <LetterOfOffer/>
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/student-advisory-note/:studentId"
          element={
            <ProtectedRoute>
              <AdvisoryNote />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/student-pre-contract-letter/:studentId"
          element={
            <ProtectedRoute>
              <StudentPreContract />
            </ProtectedRoute>
          }
          exact
        />
            <Route
          path="/student-applicationFee/:paymentId"
          element={
            <ProtectedRoute>
              <ApplicationFee />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="/student-invoice/:paymentId"
          element={
            <ProtectedRoute>
              <Invoice />
            </ProtectedRoute>
          }
          exact
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
};

export default Admin;
