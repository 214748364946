import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchStudentContractDetails } from "../../API/admin/GenerateStudentDocuments";
import EditableField from "./EditingContact";
import {
  addStudentDocumentData,
  fetchStudentDocumentData,
  editStudentDocumentData,
} from "../../API/admin/DocumentData";

const StudentPreContract = () => {
  useEffect(() => {
    handelStudentContractDataFetch();
    fetchStudentDocument();
  }, []);

  const handleDownload = () => {
    window.print();
  };
  const [studentContractLetterData, setStudentContractLetterData] = useState(
    {}
  );

 
  const [isEdited, setIsEdited] = useState(false); // Track if any field is edited
  const [showSave, setShowSave] = useState(false); // Toggle for Save button

  const [installments, setInstallments] = useState([]);
  // const studentId = useParams();
  const [nricStriked, setNricStriked] = useState(false); // Initially striked
  const [finStriked, setFinStriked] = useState(false); // Initially not striked
  const [passportStriked, setPassportStriked] = useState(false); // Initially striked

  // State for the second section (NRIC, FIN, Passport Number)
  const [nricStriked2, setNricStriked2] = useState(false); // Initially striked
  const [finStriked2, setFinStriked2] = useState(false); // Initially not striked
  const [passportStriked2, setPassportStriked2] = useState(false); // Initially striked
  const { studentId } = useParams();
 
  const handelStudentContractDataFetch = async () => {
    try {
      const response = await fetchStudentContractDetails(studentId);
      if (response.status === 200) {
        setStudentContractLetterData(response.data);
        console.log(response.data, "response.data");

        document.title = `${response?.data?.name}-Student-Contract-Letter`;
        if (response.data.installment) {
          setInstallments(response.data.installment);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
 
  const fetchStudentDocument = async () => {
    try {
      try {
        const response = await fetchStudentDocumentData(studentId);
        if (response.status === 200) {
          const data = response.data[0];
        console.log(data,"response.data");
        
          if (response.data.length > 0) {
            // Data exists, update state
            setValues({
              contractNo: data?.contractNo || "ATC 2024/09/001",
              finNo: data?.finNo || "M0270401X",
              dateField: data?.dateField || "10/12/2024",
              guardianName: data?.guardianName ,
              guardianFinNo: data?.guardianFinNo || "M0270401X",
              durationCourse:data?.durationCourse|| "6 MONTHS COURSE + 6 MONTHS IA",
              CommencementDate:data?.CommencementDate||'15/08/2025',
              completionDate:data?.completionDate||"15/08/2025"
            });
            setNricStriked(data?.nricStriked || false);
            setFinStriked(data?.finStriked || false);
            setPassportStriked(data?.passportStriked || false);
            setNricStriked2(data?.nricStriked2 || false);
            setFinStriked2(data?.finStriked2 || false);
            setPassportStriked2(data?.passportStriked2 || false);
            setFees({
              tuitionFee: data?.fees?.tuitionFee || 4500.0,
              medicalInsurance:data?.fees?.medicalInsurance || 60.0,
              resourceFee:data?.fees?.resourceFee || 440.0,
              feeProtection:data?.fees?.feeProtection || 0.0,
              gst:data?.fees?.gst || 450.0
            })
            console.log("Existing document data found", response.data);
          } else {
            addStudentDocument();
          }
        }
      } catch (error) {
        console.error("Error fetching student document data:", error);
      }
    } catch (error) {
      console.error("Error fetching student document data:", error);
    }
  };

  const addStudentDocument = async () => {
    try {
      const formData = {
        contractNo: values.contractNo,
        finNo: values.finNo,
        // guardianName: values.guardianName,
        dateField: values.dateField,
        guardianFinNo: values.guardianFinNo,
        durationCourse:values.durationCourse,
        fees,
        studentId,
        nricStriked: nricStriked,
        finStriked: finStriked,
        passportStriked: passportStriked,
        nricStriked2: nricStriked2,
        finStriked2: finStriked2,
        passportStriked2: passportStriked2,
        CommencementDate:values.CommencementDate,
        completionDate:values.completionDate
        
      };
      const response = await addStudentDocumentData(formData);

      if (response.status === 200) {
        console.log("Document Added Successfully");
      }
    } catch (error) {
      console.error("Error adding student document data:", error);
    }
  };

  // Toggle the strikethrough state
  const toggleNric = () => {
    setNricStriked((prev) => !prev);
    setIsEdited(true);
    setShowSave(true); // Hide Save button after saving
  };
  const toggleFin = () => {
    setFinStriked((prev) => !prev);
    setIsEdited(true);
    setShowSave(true); // Hide Save button after saving
  };

  const togglePassport = () => {
    setPassportStriked((prev) => !prev);
    setIsEdited(true);
    setShowSave(true); // Hide Save button after saving
  };

  // Toggle functions for the second section
  const toggleNric2 = () => {
    setNricStriked2((prev) => !prev);
    setIsEdited(true);
    setShowSave(true); // Hide Save button after saving
  };
  const toggleFin2 = () => {
    setFinStriked2((prev) => !prev);
    setIsEdited(true);
    setShowSave(true); // Hide Save button after saving
  };
  const togglePassport2 = () => {
    setPassportStriked2((prev) => !prev);
    setIsEdited(true);
    setShowSave(true); // Hide Save button after saving
  };

  // installments

  // Handle editing amount or date
  const handleInputChange = (index, field, value) => {
    const updatedInstallments = installments.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setInstallments(updatedInstallments);       
  };

  const [values, setValues] = useState({
    contractNo: "ATC 2024/09/001", // Default contract number
    finNo: "M0270401X", // Default FIN number
    // guardianName:"",
    dateField: "10/12/2024", // Default date
    guardianFinNo: "M0270401X",  // Default guardian FIN number
    durationCourse:"6 MONTHS COURSE + 6 MONTHS IA",
    CommencementDate:"15/08/2025",
    completionDate:"10/12/2024",
  });

  const handleFieldChange = (key, newValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: newValue, // Update only the field corresponding to 'key'
    }));
    setIsEdited(true);
    setShowSave(true); // Hide Save button after saving
  };

  // Editing fees
  const [isEditing, setIsEditing] = useState({
    tuitionFee: false,
    medicalInsurance: false,
    resourceFee: false,
    feeProtection: false,
    gst: false,
  });

  const [fees, setFees] = useState({
    tuitionFee: 4500.0,
    medicalInsurance: 60.0,
    resourceFee: 440.0,
    feeProtection: 0.0,
    gst: 450.0,
  });

  // Function to handle the change in amount
  const handleInputChangeFees = (e, field) => {
    setFees({
      ...fees,
      [field]: parseFloat(e.target.value) || 0,
    });
    setIsEdited(true); // Mark as edited when a value changes
    setShowSave(true); // Show Save button when a value changes
  };
  // Handle save button click
  const handleSave = async () => {
    try {
      const formData = {
        contractNo: values.contractNo,
        finNo: values.finNo,
        guardianName: values.guardianName,
        dateField: values.dateField,
        guardianFinNo: values.guardianFinNo,
        durationCourse:values.durationCourse,
        CommencementDate:values.CommencementDate,
        completionDate:values.completionDate,
        fees,
        studentId,
        nricStriked: nricStriked,
        finStriked: finStriked,
        passportStriked: passportStriked,
        nricStriked2: nricStriked2,
        finStriked2: finStriked2,
        passportStriked2: passportStriked2,
      };
      const response = await editStudentDocumentData(formData, studentId);
      if (response.status === 200) {
        console.log("Document Updated Successfully");
        setIsEdited(false); // Reset the edited flag
        setShowSave(false); // Hide Save button after saving
      }
    } catch (error) {
      console.error("Error updating student document data:", error);
    }
    // Call API to save document data
  };
  // Function to toggle between view and edit mode
  const toggleEdit = (field) => {
    setIsEditing({
      ...isEditing,
      [field]: !isEditing[field],
    });
  };

  return (
    <>
      {Object.keys(studentContractLetterData).length > 0 ? (
        <>
          <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse no-print fixed right-0">
            {showSave && (
              <button
                className="mt-3 w-full inline-flex justify-center rounded-md border border-blue-700 shadow-sm px-4 py-2 bg-blue-600 text-white text-base font-medium"
                onClick={handleSave}
              >
                Save
              </button>
            )}

            {/* Show Download button only if no edits are pending */}
            {!showSave && (
              <button
                className="mt-3 w-full inline-flex justify-center rounded-md border border-green-700 shadow-sm px-4 py-2 bg-green-600 text-white text-base font-medium"
                onClick={handleDownload}
              >
                Download
              </button>
            )}
          </div>
          <div className="bg-slate-300 w-full h-full flex flex-col justify-center items-center">
            <div className="w-[210mm] bg-white" id="offerLetter">
              <div className="p-10 pt-16">
                <div className="text-[13px] ml-9 pt-14">
                  <div className="flex justify-between text-[14px] relative bottom-8 pr-12">
                    <span className="font-semibold">
                      {/*  <EditableField
        fieldKey="finNo"
        value={values.contractNo}
        handleChange={handleFieldChange} // Pass the change handler
      />     */}
                      <EditableField
                        fieldKey="contractNo"
                        value={values.contractNo}
                        handleChange={handleFieldChange} // Pass the change handler
                      />{" "}
                    </span>
                    <span>Standard PEI-Student Contract Version 4.0</span>
                  </div>
                  <p>
                    This contract (&ldquo;<strong>Contract</strong>&rdquo;) is
                    made BETWEEN:
                  </p>
                  <p>&nbsp;</p>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p>(1)</p>
                        </td>
                        <td>
                          <p>
                            Registered Name of Private Education Institution
                            (PEI)
                          </p>
                        </td>
                        <td>
                          <p>:</p>
                        </td>
                        <td>
                          <p>
                            <u>Acetek College Pte Ltd&nbsp;</u>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>Registration Number</p>
                        </td>
                        <td>
                          <p>:</p>
                        </td>
                        <td>
                          <p>
                            <u>200821327E ________</u>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td colspan="3">
                          <p>
                            (the &ldquo;<strong>PEI</strong>&rdquo;)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
                        </td>
                        <td>
                          <p className="mb-11">Registered Address</p>
                        </td>
                        <td>
                          <p>:</p>
                        </td>
                        <td>
                          <p>
                            <u>Blk 167 Jalan Bukit Merah,</u>
                          </p>
                          <p>
                            <u>#02-12 Connection one,&nbsp;</u>
                          </p>
                          <p>
                            <u>Tower 5,</u>&nbsp;<u>Singapore 150167</u>
                          </p>
                          <p>
                            <u>&nbsp;</u>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p className="text-[13px]">
                            <strong>
                              <em>
                                (To be used if the Student is 18 and above years
                                of age).
                              </em>
                            </strong>
                          </p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="mb-6">(2)&nbsp;</p>
                        </td>
                        <td>
                          <p className="text-[14px]">
                            Full Name of Contracting Party
                          </p>
                          <p className="text-[10px]">
                            <em>
                              (The name has to be as per NRIC for Singapore
                              Citizen (SC) and Permanent Resident (PR) and as in
                              passport for foreigners.)
                            </em>
                          </p>
                        </td>
                        <td>
                          <p>:</p>
                        </td>
                        <td>
                          <p className="font-bold text-[14px] uppercase">
                            <u>{studentContractLetterData.name}</u>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>

                        {/* just strike  setup */}
                        <td>
                          {/* This paragraph contains NRIC, FIN, and Passport Number, each with clickable strike-through toggle */}
                          <p className="text-[14px]">
                            {/* Span for NRIC - toggles strike-through when clicked */}
                            <span
                              onClick={toggleNric}
                              style={{
                                textDecoration: nricStriked
                                  ? "line-through"
                                  : "none",
                                cursor: "pointer",
                              }}
                            >
                              NRIC
                            </span>
                            /
                            {/* Span for FIN - toggles strike-through when clicked */}
                            <span
                              onClick={toggleFin}
                              style={{
                                textDecoration: finStriked
                                  ? "line-through"
                                  : "none",
                                cursor: "pointer",
                              }}
                            >
                              FIN
                            </span>
                            /
                            {/* Span for Passport Number - toggles strike-through when clicked */}
                            <span
                              onClick={togglePassport}
                              style={{
                                textDecoration: passportStriked
                                  ? "line-through"
                                  : "none",
                                cursor: "pointer",
                              }}
                            >
                              Passport Number
                            </span>
                            &nbsp;
                          </p>

                          {/* Additional text explaining the usage of NRIC, FIN, and Passport Number */}
                          <p className="text-[10px]">
                            <em>
                              (NRIC number is meant to be stated where the
                              Contracting Party is an SC/PR. FIN/Passport Number
                              is meant to be stated where the Contracting Party
                              is not an SC/PR. Please delete as appropriate by
                              striking through.)
                            </em>
                          </p>
                        </td>
                        <td>
                          <p>:</p>
                        </td>
                        <td>
                          <u className="font-semibold">
                            {
                              passportStriked === false ? studentContractLetterData?.passport  :<EditableField
                              fieldKey="finNo"
                              value={values.finNo}
                              handleChange={handleFieldChange} // Pass the change handler
                            /> 
                            }
                           
                          </u>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>
                            (the &ldquo;<strong>Contracting Party</strong>
                            &rdquo;)
                          </p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>OR</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>
                            <strong>
                              <em>
                                (To be used if the Student is under 18 years of
                                age).
                              </em>
                            </strong>
                          </p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="mb-7">(2)</p>
                        </td>
                        <td>
                          <p className="text-[14px]">
                            Full Name of Contracting Party (Parent/Legal
                            Guardian)
                          </p>
                          <p className="text-[10px]">
                            <em>
                              (The name has to be as per NRIC for Singapore
                              Citizen (SC) and Permanent Resident (PR) and as in
                              passport for foreigners.)
                            </em>
                          </p>
                        </td>
                        <td>
                          <p>:</p>
                        </td>
                        <td>
                          {studentContractLetterData?.age < 18 ? (
                            <u className="font-semibold">
                                <span
            className="cursor-pointer"
          >
            {studentContractLetterData?.guardianName}
          </span>
                              {/* <EditableField
                                fieldKey="guardianName"
                                value={values.guardianName}
                                handleChange={handleFieldChange} // Pass the change handler
                              />{" "} */}
                            </u>
                          ) : (
                            <p>
                              <u>N.A_______________________</u>
                            </p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          {/* This paragraph contains NRIC, FIN, and Passport Number, each with clickable strike-through toggle */}
                          <p className="text-[14px]">
                            {/* Second section: clickable NRIC, FIN, Passport */}
                            <span
                              onClick={toggleNric2}
                              style={{
                                textDecoration: nricStriked2
                                  ? "line-through"
                                  : "none",
                                cursor: "pointer",
                              }}
                            >
                              NRIC
                            </span>
                            /
                            <span
                              onClick={toggleFin2}
                              style={{
                                textDecoration: finStriked2
                                  ? "line-through"
                                  : "none",
                                cursor: "pointer",
                              }}
                            >
                              FIN
                            </span>
                            /
                            <span
                              onClick={togglePassport2}
                              style={{
                                textDecoration: passportStriked2
                                  ? "line-through"
                                  : "none",
                                cursor: "pointer",
                              }}
                            >
                              Passport Number
                            </span>
                            &nbsp;
                          </p>
                          <p className="text-[10px]">
                            <em>
                              (NRIC number is meant to be stated where the
                              Contracting Party is an SC/PR. FIN/Passport Number
                              is meant to be stated where the Contracting Party
                              is not an SC/PR. Please delete as appropriate by
                              striking through.)
                            </em>
                          </p>
                        </td>
                        <td>
                          <p>:</p>
                        </td>
                        <td>
                          <td>
                            {studentContractLetterData?.age < 18 ? (
                              <u className="font-semibold">
                                      {
                              passportStriked2 === false ? studentContractLetterData?.guardianPassport  :<EditableField
                              fieldKey="guardianFinNo"
                              value={values?.guardianFinNo}
                              handleChange={handleFieldChange} // Pass the change handler
                            />
                            }
                                
                              </u>
                            ) : (
                              <p>
                                <u>N.A_______________________</u>
                              </p>
                            )}
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>
                            (the &ldquo;<strong>Contracting Party</strong>
                            &rdquo;) on behalf of
                          </p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p className="text-[14px]">Full Name of Student</p>
                          <p className="text-[10px]">
                            <em>
                              (The name has to be as per NRIC for Singapore
                              Citizen (SC) and Permanent Resident (PR) and as in
                              passport for foreigners.)
                            </em>
                          </p>
                        </td>
                        <td>
                          <p>:</p>
                        </td>
                        <td>
                          <p>
                            <u>N.A_______________________</u>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p className="text-[14px]">
                            NRIC/FIN/Passport Number
                          </p>
                          <p className="text-[10px]">
                            <em>
                              (NRIC number is meant to be stated where the
                              Student is an SC/PR. FIN/Passport Number is meant
                              to be stated where the Student is not an SC/PR.
                              Please delete as appropriate by striking through.)
                            </em>
                          </p>
                        </td>
                        <td>
                          <p>:</p>
                        </td>
                        <td>
                          <p>
                            <u>N.A_______________________</u>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>
                            (the &ldquo;<strong>Student</strong>&rdquo;)
                          </p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>&nbsp;</p>
                  <div className="text-center mt-44">Page 1 of 12</div>
                </div>
                <div className="flex justify-between text-[14px]   pl-12">
                  <span className="font-semibold">
                    <EditableField
                      fieldKey="contractNo"
                      value={values.contractNo}
                      handleChange={handleFieldChange} // Pass the change handler
                    />
                  </span>
                  <span>Standard PEI-Student Contract Version 4.0</span>
                </div>

                {/* //page4 */}
                <div className="text-[12px] ml-4 mr-16">
                  <div class="w-full border-none">
                    <div class="w-full border-none">
                      <div class="w-full flex">
                        <div class="w-7 p-1.5 align-top">
                          <p class="m-0 text-base font-serif mt-0.5 mb-0.5 text-justify">
                            <strong class="text-sm font-sans">1.</strong>
                          </p>
                        </div>
                        <div class="w-full p-1.5 align-top">
                          <p class="m-0 text-base font-serif mt-0.5 mb-0.5 text-justify indent-7">
                            <strong class="text-sm ">DEFINITIONS</strong>
                          </p>
                        </div>
                      </div>
                      <div class="w-full flex">
                        <div class="w-7 p-1.5 align-top">
                          <p class="m-0 text-base font-serif mt-1.5 mb-1.5 text-justify">
                            <strong class="text-sm ">1.1</strong>
                          </p>
                        </div>
                        <div class="w-full p-1.5 align-top">
                          <p class="m-0 text-base font-serif mt-1.5 text-justify indent-7">
                            <span class="text-sm ">
                              In the Contract, the following words and
                              expressions shall have the following
                              meanings:&nbsp;
                            </span>
                          </p>
                        </div>
                      </div>

                      <div class="w-full ml-7 flex flex-col gap-2 border-none">
                        <div class="w-full flex">
                          <div class="w-1/2  align-top">
                            <p class="m-0 text-base font-serif pl-7  text-justify">
                              <span>
                                &ldquo;<strong>Cooling-Off Period</strong>
                                &rdquo;
                              </span>
                            </p>
                          </div>
                          <div class="w-1/2  align-top">
                            <p class="m-0 font-serif ">
                              <span class="text-sm font-sans">
                                Shall refer to the period of ten (10) calendar
                                days commencing from and including the date of
                                this Contract.
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="w-full flex">
                          <div class="w-1/2  align-top">
                            <p class="m-0 text-base font-serif pl-7  text-justify">
                              <span>
                                &ldquo;<strong>Course</strong>&rdquo;
                              </span>
                            </p>
                          </div>
                          <div class="w-1/2  align-top">
                            <p class="m-0 font-serif ">
                              <span class="text-sm font-sans">
                                Shall refer to the course described in Schedule
                                A.
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="w-full flex">
                          <div class="w-1/2  align-top">
                            <p class="m-0 text-base font-serif pl-7  text-justify">
                              <span>
                                &ldquo;<strong>Course Fee</strong>&rdquo;
                              </span>
                            </p>
                          </div>
                          <div class="w-1/2  align-top">
                            <p class="m-0 font-serif ">
                              <span class="text-sm font-sans">
                                Shall refer to the compulsory fees to be charged
                                by the PEI on account of the Student’s
                                undertaking of the Course and as stated in
                                Schedule B.
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="w-full flex">
                          <div class="w-1/2  align-top">
                            <p class="m-0 text-base font-serif pl-7  text-justify">
                              <span>
                                &ldquo;<strong>Course Commencement Date</strong>
                                &rdquo;
                              </span>
                            </p>
                          </div>
                          <div class="w-1/2  align-top">
                            <p class="m-0 font-serif ">
                              <span class="text-sm font-sans">
                                Shall refer to the date of commencement of the
                                Course as scheduled by the PEI and shall be as
                                stated in Item 4 of Schedule A.
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="w-full flex">
                          <div class="w-1/2  align-top">
                            <p class="m-0 text-base font-serif pl-7  text-justify">
                              <span>
                                &ldquo;<strong>Course Completion Date</strong>
                                &rdquo;
                              </span>
                            </p>
                          </div>
                          <div class="w-1/2  align-top">
                            <p class="m-0 font-serif ">
                              <span class="text-sm font-sans">
                                Shall refer to the date of completion of the
                                Course as scheduled by the PEI, and shall be as
                                stated in Item 5 of Schedule A.
                              </span>
                            </p>
                          </div>
                        </div>

                        <div class="w-full flex">
                          <div class="w-1/2  align-top">
                            <p class="m-0 text-base font-serif pl-7  text-justify">
                              <span>
                                &ldquo;<strong>Developer/Proprietor</strong>
                                &rdquo;
                              </span>
                            </p>
                          </div>
                          <div class="w-1/2  align-top">
                            <p class="m-0 font-serif ">
                              <span class="text-sm font-sans">
                                Shall refer to the person who developed the
                                Course, or who is the proprietor of the Course,
                                as stated in Item 8 of Schedule A.
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="w-full flex">
                          <div class="w-1/2  align-top">
                            <p class="m-0 text-base font-serif pl-7  text-justify">
                              <span>
                                &ldquo;<strong>ICA</strong>
                                &rdquo;
                              </span>
                            </p>
                          </div>
                          <div class="w-1/2  align-top">
                            <p class="m-0 font-serif ">
                              <span class="text-sm font-sans">
                                Shall have the meaning assigned to it in Clause
                                3.1(e).
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="w-full flex">
                          <div class="w-1/2  align-top">
                            <p class="m-0 text-base font-serif pl-7  text-justify">
                              <span>
                                &ldquo;<strong>Miscellaneous Fees</strong>
                                &rdquo;
                              </span>
                            </p>
                          </div>
                          <div class="w-1/2  align-top">
                            <p class="m-0 font-serif ">
                              <span class="text-sm font-sans">
                                Shall refer to non-compulsory fees potentially
                                chargeable by the PEI on account of, or arising
                                from, the Student’s undertaking of the Course,
                                and as described in Schedule C.
                              </span>
                            </p>
                          </div>
                        </div>

                        <div class="w-full flex">
                          <div class="w-1/2  align-top">
                            <p class="m-0 text-base font-serif pl-7  text-justify">
                              <span>
                                &ldquo;
                                <strong>Permitted Course Duration</strong>
                                &rdquo;
                              </span>
                            </p>
                          </div>
                          <div class="w-1/2  align-top">
                            <p class="m-0 font-serif ">
                              <span class="text-sm font-sans">
                                Shall refer to the permitted duration of the
                                Course starting on and from the Course
                                Commencement Date and ending on the Course
                                Completion Date (both dates inclusive).
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="w-full flex">
                          <div class="w-1/2  align-top">
                            <p class="m-0 text-base font-serif pl-7 ">
                              <span>
                                &ldquo;
                                <strong>
                                  Private Education Mediation-Arbitration Scheme
                                </strong>
                                &rdquo;
                              </span>
                            </p>
                          </div>
                          <div class="w-1/2  align-top">
                            <p class="m-0 font-serif ">
                              <span class="text-sm font-sans">
                                Shall refer to the dispute resolution scheme
                                under the Private Education (Dispute Resolution
                                Schemes) Regulations 2016.
                              </span>
                            </p>
                          </div>
                        </div>

                        <div class="w-full flex">
                          <div class="w-1/2  align-top">
                            <p class="m-0 text-base font-serif pl-7  text-justify">
                              <span>
                                &ldquo;
                                <strong>Refund Event</strong>
                                &rdquo;
                              </span>
                            </p>
                          </div>
                          <div class="w-1/2  align-top">
                            <p class="m-0 font-serif ">
                              <span class="text-sm font-sans">
                                Shall have the meaning assigned to it in Clause
                                3.1
                              </span>
                            </p>
                          </div>
                        </div>

                        <div class="w-full flex">
                          <div class="w-1/2  align-top">
                            <p class="m-0 text-base font-serif pl-7  text-justify">
                              <span>
                                &ldquo;
                                <strong>SSG</strong>
                                &rdquo;
                              </span>
                            </p>
                          </div>
                          <div class="w-1/2  align-top">
                            <p class="m-0 font-serif ">
                              <span class="text-sm font-sans">
                                Shall refer to the SkillsFuture Singapore Agency
                                established pursuant to Section 3 of the
                                SkillsFuture Singapore Agency Act 2016.
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="w-full flex">
                          <div class="w-1/2  align-top">
                            <p class="m-0 text-base font-serif pl-7  text-justify">
                              <span>
                                &ldquo;
                                <strong>Student Pass</strong>
                                &rdquo;
                              </span>
                            </p>
                          </div>
                          <div class="w-1/2  align-top">
                            <p class="m-0 font-serif ">
                              <span class="text-sm font-sans">
                                Shall be as described on www.ica.gov.sg or such
                                other website which operates in lieu thereof.{" "}
                              </span>
                            </p>
                          </div>
                        </div>

                        <div className="text-center mt-36">Page 2 of 12</div>
                      </div>

                      <div>
                        {/* //5 page */}
                        <div className="flex justify-between text-[14px]   pl-12">
                          <span className="font-semibold">
                            <EditableField
                              fieldKey="contractNo"
                              value={values.contractNo}
                              handleChange={handleFieldChange} // Pass the change handler
                            />
                          </span>
                          <span>Standard PEI-Student Contract Version 4.0</span>
                        </div>

                        <div className="flex flex-col gap-2">
                          <div className="flex  text-[15px]  font-bold gap-4 mt-10">
                            <p>2.</p>
                            <span>COURSE INFORMATION AND FEES</span>
                          </div>
                          <span className="flex text-[14px] gap-4 ">
                            <span className="font-semibold">2.1</span>
                            <p className="flex flex-col gap-2">
                              <p className="">
                                The PEI shall provide the Course as set out in
                                Schedule A to the Student. The PEI shall not
                                make any change to any detail of the Course set
                                out in Schedule A unless it has obtained the
                                prior written consent of the Contracting Party
                                and, where required under the Private Education
                                Act 2009 or the subsidiary legislation
                                thereunder, the prior written consent of SSG
                              </p>
                              <p>
                                For the avoidance of doubt, if it is stated in
                                Schedule A that the Course includes industrial
                                attachment, the PEI shall use reasonable
                                endeavours to ensure that such industrial
                                attachment is provided to the Student.
                              </p>
                            </p>
                          </span>
                          <div className="">
                            <span className="flex   flex-col text-[14px] gap-4 ">
                              <span className="flex gap-3">
                                <span className="font-semibold">2.2</span>
                                <span>
                                  The PEI represents and warrants that:
                                </span>
                              </span>
                              <p className="flex flex-col gap-2">
                                <ol className=" list-[lower-alpha]  pl-14">
                                  <li>
                                    The person stated in Item 8 of Schedule A is
                                    the Developer/Proprietor of the Course and
                                    that the PEI has obtained all necessary
                                    permissions, licenses and approvals for the
                                    provision of the Course to the Student.
                                  </li>
                                  <li>
                                    The duration of the course, including
                                    holidays and examination schedules, and
                                    contact hours by days and week;
                                  </li>
                                  <li>
                                    It has obtained SSG’s permission to conduct
                                    the Course and that it has not made any such
                                    changes to the Course which would require it
                                    to re-apply to SSG for permission to conduct
                                    the Course.
                                  </li>
                                  <li>
                                    The PEI has verified that the Student meets
                                    the Course entry requirements set out in
                                    Item 10 of Schedule A.
                                  </li>
                                  <li>
                                    The information set out in Items 1 to 5 and
                                    7 to 17 of Schedule A is correct, complete
                                    and not inconsistent with the details
                                    submitted to the SSG to obtain its
                                    permission to provide the Course.
                                  </li>
                                </ol>
                              </p>
                            </span>
                          </div>
                          <span className="flex text-[14px] gap-4 ">
                            <span className="font-semibold">2.3</span>
                            <p className="flex flex-col gap-2">
                              <p className="">
                                PEI undertakes that the Student will be awarded
                                or conferred the qualification stated in Item 7
                                of Schedule A by the organisation named in Item
                                9 of Schedule A upon the Student’s successful
                                completion of the Course, and having met all the
                                requirements of the award/qualification.
                              </p>
                            </p>
                          </span>

                          <span className="flex text-[14px] gap-4 ">
                            <span className="font-semibold">2.4</span>
                            <p className="flex flex-col gap-2">
                              <p className="">
                                The parties agree that Schedule B and Schedule C
                                set out all fees payable (potentially or
                                otherwise) by the Contracting Party to the PEI
                                for the Course or arising from the Student’s
                                undertaking of the Course.
                              </p>
                            </p>
                          </span>
                          <span className="flex text-[14px] gap-4 ">
                            <span className="font-semibold">2.5</span>
                            <p className="flex flex-col gap-2">
                              <p className="">
                                The Contracting Party shall pay the Course Fees
                                in the amount and by the timelines as stated in
                                the instalment schedule in Schedule B and the
                                Miscellaneous Fees as per the timelines stated
                                in each invoice for the Miscellaneous Fees
                                issued by the PEI to the Contracting Party.
                              </p>
                              <p>
                                The PEI considers a payment made [ 7 ]
                                days/month after the scheduled due date(s) in
                                Schedule B for the Course Fees and [ 7 ]
                                days/month after the scheduled due date(s) in
                                the invoices for the Miscellaneous Fees as late.
                                The PEI will explain to the Student its policy
                                for the late payment of Course Fees and
                                Miscellaneous Fees, and any impact on the
                                Course/module completion (if applicable).
                              </p>
                            </p>
                          </span>
                          <span className="flex text-[14px] gap-4 ">
                            <span className="font-semibold">3</span>
                            <p className="flex flex-col gap-2">
                              <p className="font-semibold">
                                TERMINATION AND REFUND POLICY (Please refer to
                                the diagram in Schedule E)
                              </p>
                            </p>
                          </span>
                          <span className="flex text-[14px] gap-4 ">
                            <span className="font-semibold">3.1</span>
                            <p className="flex flex-col gap-2">
                              <p className="">
                                The PEI will notify the Student in writing
                                within three (3) working days after becoming
                                aware of any of the following (each a{" "}
                                <span className="font-semibold">
                                  {" "}
                                  “Refund Event”{" "}
                                </span>
                                ):
                              </p>
                              <div className="text-center  mt-10 ">
                                Page 3 of 12
                              </div>
                              <div className="flex justify-between text-[14px]   pl-12">
                                <span className="font-semibold">
                                  <EditableField
                                    fieldKey="contractNo"
                                    value={values.contractNo}
                                    handleChange={handleFieldChange} // Pass the change handler
                                  />
                                </span>
                                <span>
                                  Standard PEI-Student Contract Version 4.0
                                </span>
                              </div>

                              <ol className=" list-[lower-alpha]  pl-8">
                                <li>
                                  It cannot commence the provision of the Course
                                  on the Course Commencement Date;
                                </li>
                                <li>
                                  It cannot commence the provision of the Course
                                  on the Course Commencement Date;
                                </li>
                                <li>
                                  The Course will be terminated before the
                                  Course Completion Date;
                                </li>
                                <li>
                                  The Student does not meet the course entry or
                                  matriculation requirements as stated in
                                  Schedule A; or
                                </li>
                                <li>
                                  The Immigration & Checkpoints Authority of
                                  Singapore (the “ICA”) rejects the Student’s
                                  application for the Student Pass.
                                </li>
                              </ol>
                            </p>
                          </span>
                        </div>

                        <div>
                          {/* //page6 */}

                          <div className="flex flex-col gap-2">
                            <div className="flex   flex-col text-[14px]">
                              <span className="flex    gap-4 mt-10">
                                <p>3.2</p>
                                <span>
                                  Where any of the Refund Events in Clause
                                  3.1(a) to (c) above has occurred:
                                </span>
                              </span>
                              <ol className=" list-[lower-alpha]  pl-16 mt-2">
                                <li>
                                  The PEI shall use reasonable efforts to make
                                  alternative study arrangements for the Student
                                  and shall propose such alternative study
                                  arrangements in writing to the Contracting
                                  Party, within ten (10) working days of
                                  informing the Contracting Party of the Refund
                                  Event.
                                </li>
                                <li>
                                  If the Contracting Party accepts such
                                  alternative study arrangements, the PEI shall
                                  set forth such alternative study arrangements
                                  in a written contract and this Contract shall
                                  automatically terminate on the date that such
                                  new written contract comes into effect.
                                </li>
                                <li>
                                  If the PEI does not propose alternative study
                                  arrangements to the Contracting Party within
                                  the time stipulated in Clause 3.2(a) above, or
                                  the Contracting Party does not accept such
                                  alternative study arrangements, the
                                  Contracting Party may forthwith terminate this
                                  Contract by way of a written notice to the
                                  PEI.
                                </li>
                              </ol>
                            </div>
                            <span className="flex text-[14px] gap-4 ">
                              <span className="font-semibold">3.3</span>
                              <p className="flex flex-col gap-2">
                                <p className="">
                                  Where any of the Refund Events in Clauses
                                  3.1(d) to (e) has occurred, the PEI shall
                                  forthwith terminate this Contract by way of a
                                  written notice to the Contracting Party.
                                </p>
                              </p>
                            </span>

                            <span className="flex text-[14px] gap-4 ">
                              <span className="font-semibold">3.4</span>
                              <p className="flex flex-col gap-2">
                                <p className="">
                                  If the Contract is terminated pursuant to
                                  Clause 3.2(b) read with Clause 3.1(a), the PEI
                                  shall refund all Course Fees and Miscellaneous
                                  Fees paid by the Contracting Party within
                                  seven (7) working days of the termination.
                                </p>
                              </p>
                            </span>
                            <span className="flex text-[14px] gap-4 ">
                              <span className="font-semibold">3.5</span>
                              <p className="flex flex-col gap-2">
                                <p className="">
                                  If the Contract is terminated pursuant to
                                  Clause 3.2(b) read with either Clause 3.1(b)
                                  or Clause 3.1(c), the PEI shall refund the
                                  Course Fees and Miscellaneous Fees in
                                  proportion to the uncompleted portion or
                                  duration of the Course, whichever is higher,
                                  to the Contracting Party within seven (7)
                                  working days of the termination.
                                </p>
                              </p>
                            </span>
                            <span className="flex text-[14px] gap-4 ">
                              <span className="font-semibold">3.6</span>
                              <p className="flex flex-col gap-2">
                                <p className="">
                                  If the Contract is terminated pursuant to
                                  Clause 3.3 or Clause 3.2(c) read with Clause
                                  3.1(a), the PEI shall refund all Course Fees
                                  and Miscellaneous Fees paid by the Contracting
                                  Party within seven (7) working days of the
                                  termination.
                                </p>
                              </p>
                            </span>
                            <span className="flex text-[14px] gap-4 ">
                              <span className="font-semibold">3.7</span>
                              <p className="flex flex-col gap-2">
                                <p className="">
                                  If the Contract is terminated pursuant to
                                  Clause 3.2(c) read with either Clause 3.1(b)
                                  or Clause 3.1(c), the PEI shall refund the
                                  Course Fees and Miscellaneous Fees in
                                  proportion to the uncompleted portion or
                                  duration of the Course, whichever is higher,
                                  to the Contracting Party within seven (7)
                                  working days of the termination.
                                </p>
                              </p>
                            </span>
                            <span className="flex  flex-col text-[14px]  ">
                              <span className="flex gap-2">
                                <span className="font-semibold">3.8</span>
                                <span className="font-semibold">
                                  {" "}
                                  <u>
                                    {" "}
                                    Refund for Withdrawal During the Cooling-Off
                                    Period:
                                  </u>
                                </span>
                              </span>
                              <p className="ml-8">
                                Notwithstanding anything herein contained, the
                                Contracting Party shall be entitled to, without
                                any liability whatsoever to the PEI, forthwith
                                terminate the Contract at any time within the
                                Cooling-Off Period by way of a written notice to
                                the PEI. The PEI shall return all Course Fees
                                and Miscellaneous Fees paid to it within seven
                                (7) working days of the receipt of the written
                                notice.
                              </p>
                            </span>
                          </div>
                          <div className="text-center  mt-3">Page 4 of 12</div>
                          <div className="flex justify-between text-[14px]   pl-12">
                            <span className="font-semibold">
                              <EditableField
                                fieldKey="contractNo"
                                value={values.contractNo}
                                handleChange={handleFieldChange} // Pass the change handler
                              />
                            </span>
                            <span>
                              Standard PEI-Student Contract Version 4.0
                            </span>
                          </div>

                          <div className="mt-5 flex flex-col gap-2">
                            <span className="flex  flex-col text-[14px] gap-2  ">
                              <span className="flex gap-2">
                                <span className="font-semibold">3.9</span>
                                <span className="font-semibold">
                                  {" "}
                                  <u>
                                    {" "}
                                    Refund for Withdrawal Outside the
                                    Cooling-Off Period:
                                  </u>
                                </span>
                              </span>
                              <p className="ml-8">
                                Without prejudice to Clauses 3.1 to 3.8 above,
                                the Contracting Party may terminate the Contract
                                at any time before the Course Completion Date by
                                providing a written notice to the PEI. Upon
                                receipt of such notice, the PEI shall within
                                seven (7) working days, refund to the
                                Contracting Party such amount (if any) as
                                determined in accordance with Schedule D.
                              </p>
                            </span>

                            <span className="flex text-[14px] gap-4 mt-3 ">
                              <span className="font-semibold ">4.</span>
                              <p className="flex flex-col gap-2">
                                <p className="font-semibold">
                                  ADDITIONAL INFORMATION
                                </p>
                              </p>
                            </span>
                            <span className="flex text-[14px] gap-4 ">
                              <span className="font-semibold">4.1</span>
                              <p className="flex flex-col gap-2">
                                <p className="">
                                  This Contract shall be interpreted in
                                  accordance with the laws of Singapore. Subject
                                  to the Private Education (Dispute Resolution
                                  Schemes) Regulations 2016, the courts of
                                  Singapore shall have exclusive jurisdiction to
                                  settle any claim, dispute or disagreement
                                  arising out of or relating to this Contract.
                                </p>
                              </p>
                            </span>
                            <span className="flex text-[14px] gap-4 ">
                              <span className="font-semibold">4.2</span>
                              <p className="flex flex-col gap-2">
                                <p className="">
                                  If any provision of this Contract is adjudged
                                  to be illegal, invalid or unenforceable, in
                                  whole or in part, such provision or part of it
                                  shall, to the extent that it is illegal,
                                  invalid or unenforceable, be deemed not to
                                  form part of this Contract and shall not
                                  affect the validity, legality and
                                  enforceability of the remainder of this
                                  Contract
                                </p>
                              </p>
                            </span>
                            <span className="flex text-[14px] gap-4 ">
                              <span className="font-semibold">4.3</span>
                              <p className="flex flex-col gap-2">
                                <p className="">
                                  The PEI shall treat all personal information
                                  provided by the Student or Contracting Party
                                  as strictly confidential and shall not
                                  disclose any such personal information to any
                                  third-party, unless it has obtained the prior
                                  written consent of the Contracting Party or
                                  such disclosure is required under the law.
                                </p>
                              </p>
                            </span>

                            <span className="flex text-[14px] gap-4 ">
                              <span className="font-semibold">4.4</span>
                              <p className="flex flex-col gap-2">
                                <p className="">
                                  This Contract contains the whole agreement
                                  between the parties in respect of its subject
                                  matter and supersedes all previous
                                  discussions, correspondences and understanding
                                  between the parties in respect of such subject
                                  matter.
                                </p>
                              </p>
                            </span>

                            <span className="flex text-[14px] gap-4 ">
                              <span className="font-semibold">4.5</span>
                              <p className="flex flex-col gap-2">
                                <p className="">
                                  In no event shall any delay, failure or
                                  omission on the part of either party in
                                  exercising any right, power, privilege, claim
                                  or remedy arising under or pursuant to this
                                  Contract constitute a waiver of that right,
                                  power, privilege, claim or remedy, unless
                                  expressly given in writing. No waiver of a
                                  breach of this Contract shall be deemed to be
                                  a waiver of any other or subsequent breach of
                                  this Contract.
                                </p>
                              </p>
                            </span>

                            <span className="flex text-[14px] gap-4 ">
                              <span className="font-semibold">4.6</span>
                              <p className="flex flex-col gap-2">
                                <p className="">
                                  If this Contract is also signed in or
                                  translated into any language other than
                                  English, the English language version shall
                                  prevail in the event of any inconsistency.
                                </p>
                              </p>
                            </span>
                            <span className="flex text-[14px] gap-4 ">
                              <span className="font-semibold">4.7</span>
                              <p className="flex flex-col gap-2">
                                <p className="">
                                  A person who is not a party to this Contract
                                  shall have no right under the Contracts (Right
                                  of Third Parties) Act 2001 to enforce any of
                                  its terms.
                                </p>
                              </p>
                            </span>
                            <div className="text-center mt-72">
                              Page 5 of 12
                            </div>
                            <div className="flex justify-between text-[14px]   pl-12">
                              <span className="font-semibold">
                                <EditableField
                                  fieldKey="contractNo"
                                  value={values.contractNo}
                                  handleChange={handleFieldChange} // Pass the change handler
                                />
                              </span>
                              <span>
                                Standard PEI-Student Contract Version 4.0
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* Nextpage */}
                        <div>
                          <div className="mt-12">
                            <div className="flex justify-center">
                              {" "}
                              <span className="text-center text-[20px]  font-bold mt-10">
                                SCHEDULE A <br /> <u>COURSE DETAILS </u>
                              </span>
                            </div>
                            <div className="relative">
                              <table className="text-[15px] mt-6 border  border-black">
                                <tbody>
                                  <tr className="border  border-black">
                                    <td className="text-[13px] w-[260px] p-5 border border-black">
                                      1) Course title
                                    </td>
                                    <td className="border p-1  border-black">
                                      {studentContractLetterData.course}
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="text-[14px] p-3 flex flex-col gap-2 border">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 2) </span>{" "}
                                        <span>
                                          {" "}
                                          Permitted Course Duration (in <br />{" "}
                                          months){" "}
                                        </span>{" "}
                                      </span>
                                      <br />
                                      <span className="text-[12px]">
                                        Note: This does not include the period
                                        of the industrial attachment, if any.
                                      </span>
                                    </td>
                                    <td className="border pb-16 p-1 border-black">
                                      {/* {
                                        studentContractLetterData?.durationOfCourse
                                      } */}
                                      <EditableField
                                      fieldKey="durationCourse"
                                      value={values.durationCourse}
                                      handleChange={handleFieldChange} 

                                      />
                                      {/* 6 MONTHS COURSE + 6 MONTHS IA */}
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="border text-[14px] p-3 border-black">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 3) </span>{" "}
                                        <span>
                                          {" "}
                                          Whether the Course is a full-time or
                                          part-time Course{" "}
                                        </span>{" "}
                                      </span>
                                    </td>
                                    <td className="border pb-7 p-1 border-black">
                                      Full-time
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="border text-[13px] p-3 border-black">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 4) </span>{" "}
                                        <span>
                                          {" "}
                                          Course Commencement Date (DD/MM/YYYY)
                                        </span>{" "}
                                      </span>
                                    </td>
                                    <td className="border pb-7 p-1 border-black">
                                    <EditableField
                                      fieldKey="CommencementDate"
                                      value={values.CommencementDate}
                                      handleChange={handleFieldChange} 

                                      />
                                      
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="border text-[13px] p-3 border-black">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 5) </span>{" "}
                                        <span>
                                          {" "}
                                          Course Completion Date (DD/MM/YYYY){" "}
                                        </span>{" "}
                                      </span>
                                    </td>
                                    <td className="border pb-7 p-1 border-black">
                              
                                      <EditableField
                                      fieldKey="completionDate"
                                      value={values.completionDate}
                                      handleChange={handleFieldChange} 

                                      />
                                    
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="border text-[13px] p-3 flex flex-col gap-4 ">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 6) </span>{" "}
                                        <span>
                                          {" "}
                                          Date of Commencement of studies if
                                          later than Course Commencement Date{" "}
                                        </span>{" "}
                                      </span>

                                      <span className="text-[12px]">
                                        Note: “N.A.” if both dates are the same
                                      </span>
                                    </td>
                                    <td className="border pb-20 p-1  border-black ">
                                      N.A
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="border text-[13px] p-3 flex flex-col gap-4 ">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 7) </span>{" "}
                                        <span>
                                          {" "}
                                          Qualification (Name of qualification
                                          to be conferred on the Student upon
                                          the successful completion of the
                                          Course)
                                        </span>{" "}
                                      </span>
                                    </td>
                                    <td className="border pb-20 p-1  border-black ">
                                      {studentContractLetterData.course
                                        .split(" ")
                                        .map((word, index) => (
                                          <span key={index}>
                                            {word.toUpperCase()}
                                            {index <
                                              studentContractLetterData.course.split(
                                                " "
                                              ).length -
                                                1 && (
                                              <span>
                                                &nbsp;&nbsp;
                                              </span>
                                            )}
                                          </span>
                                        ))}
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="border text-[13px] p-3 border-black">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 8) </span>{" "}
                                        <span>
                                          {" "}
                                          Developer/Proprietor of the Course
                                        </span>{" "}
                                      </span>
                                    </td>
                                    <td className="border pb-7 p-1 border-black">
                                      ACETEK COLLEGE PTE LTD
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="border text-[13px] p-3 border-black">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 9) </span>{" "}
                                        <span>
                                          {" "}
                                          Organisation which awards/ confers the
                                          qualification{" "}
                                        </span>{" "}
                                      </span>
                                    </td>
                                    <td className="border pb-7 p-1 border-black">
                                      ACETEK COLLEGE PTE LTD
                                    </td>
                                  </tr>

                                  <tr className="border border-black">
                                    <td className="border text-[13px] p-3 relative bottom-11 border-black">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 10) </span>{" "}
                                        <span>
                                          {" "}
                                          Course entry requirement(s)
                                        </span>{" "}
                                      </span>
                                    </td>
                                    <td className="border pb-4 text-[14px] pl-7 border-black">
                                      <ol>
                                        {studentContractLetterData?.courseRequirements
                                          ?.split("\n")
                                          .filter(
                                            (requirement) =>
                                              requirement.trim() !== ""
                                          ) // Filter out empty lines
                                          .map((requirement, index) => (
                                            <li
                                              key={index}
                                              className="flex gap-2"
                                            >
                                              <span>{index + 1}.</span>
                                              <p>{requirement.trim()}</p>
                                            </li>
                                          ))}
                                      </ol>
                                    </td>
                                  </tr>

                                  <tr className="border border-black ">
                                    <td className="border text-[13px] p-3 flex flex-col gap-4 ">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 11) </span>{" "}
                                        <span>
                                          {" "}
                                          Course schedule (with modules and/or
                                          subjects referred to)
                                        </span>{" "}
                                      </span>

                                      <span className="text-[12px]">
                                        Note: Attachment(s) may be included to
                                        show the information.
                                      </span>
                                    </td>
                                    <td className="border pb-20 p-1  border-black ">
                                      REFER TO ANNEX A
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="border text-[13px] p-3 flex flex-col gap-4 ">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 12) </span>{" "}
                                        <span>
                                          {" "}
                                          Scheduled holidays (public and school)
                                          and/or semester/term breaks relevant
                                          to the Course
                                        </span>{" "}
                                      </span>

                                      <span className="text-[12px]">
                                        Note: Attachment(s) may be included to
                                        show the information.
                                      </span>
                                    </td>
                                    <td className="border pb-20 p-1  border-black ">
                                      REFER TO ANNEX A
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="border text-[13px] p-3 flex flex-col  ">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 13) </span>{" "}
                                        <span>
                                          {" "}
                                          Examination and/or other assessment
                                          and/or assignment period(s){" "}
                                        </span>{" "}
                                      </span>

                                      <span className="text-[12px]">
                                        Note: Attachment(s) may be included to
                                        show the information.
                                      </span>
                                    </td>
                                    <td className="border pb-20 p-1  border-black ">
                                      REFER TO ANNEX A{" "}
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="border text-[13px] p-3 flex flex-col  ">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 14) </span>{" "}
                                        <span>
                                          {" "}
                                          Expected final examination results
                                          release date (DD/MM/YYYY){" "}
                                        </span>{" "}
                                      </span>

                                      <span className="text-[12px]">
                                        Note: The date shall not be more than
                                        three (3) months after the completion of
                                        the final examination, unless otherwise
                                        permitted by SSG.{" "}
                                      </span>
                                    </td>
                                    <td className="border pb-28 p-1  border-black ">
                                      {new Date(
                                        studentContractLetterData?.finalExamResultDate
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="border text-[13px] p-3 border-black">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 15) </span>{" "}
                                        <span>
                                          {" "}
                                          Expected date of conferment of the
                                          qualification (DD/MM/YYYY)
                                        </span>{" "}
                                      </span>
                                    </td>
                                    <td className="border pb-7 p-1 border-black">
                                      {new Date(
                                        studentContractLetterData?.confermentDate
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="border text-[13px] p-3 border-black">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 16) </span>{" "}
                                        <span>
                                          {" "}
                                          Does the Course include any industrial
                                          attachment?
                                        </span>{" "}
                                      </span>
                                    </td>
                                    <td className="border pb-7 p-1 border-black">
                                      Yes/ <strike>No</strike> (delete as
                                      appropriate)
                                    </td>
                                  </tr>
                                  <tr className="border border-black">
                                    <td className="border text-[13px] p-3 border-black">
                                      <span className="flex gap-2">
                                        {" "}
                                        <span> 17) </span>{" "}
                                        <span>
                                          {" "}
                                          Duration of the industrial attachment
                                        </span>{" "}
                                      </span>
                                    </td>
                                    <td className="border pb-7 p-1 border-black">
                                      6 MONTHS
                                    </td>
                                  </tr>
                                </tbody>
                              </table>{" "}
                              <div className="text-center print:text-black text-white relative bottom-[47rem] ">
                                Page 6 of 12
                              </div>
                              {/* <div className="flex justify-between text-[14px]   pl-12">
                    <span className="font-semibold">{studentContractLetterData?.studentContractNo}</span>
                    <span>Standard PEI-Student Contract Version 4.0</span>
                  </div> */}
                            </div>
                          </div>
                          <div className="text-center mt-[330px] ">
                            Page 7 of 12
                          </div>
                        </div>
                        {/* Nextpage */}

                        <div>
                          <div className="flex justify-between text-[14px]   pl-12">
                            <span className="font-semibold">
                              <EditableField
                                fieldKey="contractNo"
                                value={values.contractNo}
                                handleChange={handleFieldChange} // Pass the change handler
                              />
                            </span>
                            <span>
                              Standard PEI-Student Contract Version 4.0
                            </span>
                          </div>
                          <div className="h-[66rem]">
                            <div className="flex justify-center">
                              {" "}
                              <span className="text-center text-[20px]  font-bold mt-10">
                                SCHEDULE B <br /> <u>COURSE DETAILS </u>
                              </span>
                            </div>
                            <div className="flex justify-center">
                              <table className="border border-black text-[14px]">
                                <thead>
                                  <tr>
                                    <th className="border bg-[#BFBFBF] border-black w-[460px] text-[15px] font-bold">
                                      Fees Breakdown
                                    </th>
                                    <th className="border bg-[#BFBFBF] border-black text-[15px] font-bold p-2">
                                      Total Payable <br /> (with GST, if any){" "}
                                      <br /> (S$)
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="border border-black">
                                  <tr>
                                    <td className="p-2 border-r-black border-r-[1px]">
                                      Note: Show full breakdown of total payable
                                      course fees.
                                    </td>
                                    <td></td>
                                  </tr>
                                  {/* Tuition Fee */}
                                  <tr>
                                    <td className="pl-2 border-r-black border-r-[1px]">
                                      Tuition Fee
                                    </td>
                                    <td className="text-center">
                                      {isEditing.tuitionFee ? (
                                        <input
                                          type="number"
                                          value={fees?.tuitionFee}
                                          onChange={(e) =>
                                            handleInputChangeFees(
                                              e,
                                              "tuitionFee"
                                            )
                                          }
                                          onBlur={() =>
                                            toggleEdit("tuitionFee")
                                          }
                                          autoFocus
                                          className="border border-black text-center"
                                        />
                                      ) : (
                                        <span
                                          onClick={() =>
                                            toggleEdit("tuitionFee")
                                          }
                                        >
                                          {fees?.tuitionFee.toFixed(2)}
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  {/* Medical Insurance */}
                                  <tr>
                                    <td className="pl-2 border-r-black border-r-[1px]">
                                      Medical Insurance
                                    </td>
                                    <td className="text-center">
                                      {isEditing.medicalInsurance ? (
                                        <input
                                          type="number"
                                          value={fees?.medicalInsurance}
                                          onChange={(e) =>
                                            handleInputChangeFees(
                                              e,
                                              "medicalInsurance"
                                            )
                                          }
                                          onBlur={() =>
                                            toggleEdit("medicalInsurance")
                                          }
                                          autoFocus
                                          className="border border-black text-center"
                                        />
                                      ) : (
                                        <span
                                          onClick={() =>
                                            toggleEdit("medicalInsurance")
                                          }
                                        >
                                          {fees?.medicalInsurance.toFixed(2)}
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  {/* Resource Fee */}
                                  <tr>
                                    <td className="pl-2 border-r-black border-r-[1px]">
                                      Resource Fee
                                    </td>
                                    <td className="text-center">
                                      {isEditing.resourceFee ? (
                                        <input
                                          type="number"
                                          value={fees?.resourceFee}
                                          onChange={(e) =>
                                            handleInputChangeFees(
                                              e,
                                              "resourceFee"
                                            )
                                          }
                                          onBlur={() =>
                                            toggleEdit("resourceFee")
                                          }
                                          autoFocus
                                          className="border border-black text-center"
                                        />
                                      ) : (
                                        <span
                                          onClick={() =>
                                            toggleEdit("resourceFee")
                                          }
                                        >
                                          {fees?.resourceFee.toFixed(2)}
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  {/* Fee Protection Scheme */}
                                  <tr>
                                    <td className="pl-2 pb-6 border-r-black border-r-[1px]">
                                      <span className="text-center">
                                        {" "}
                                        Fee Protection Scheme Fee
                                      </span>
                                    </td>
                                    <td className="text-center  pb-6">
                                      {isEditing.feeProtection ? (
                                        <input
                                          type="number"
                                          value={fees?.feeProtection}
                                          onChange={(e) =>
                                            handleInputChangeFees(
                                              e,
                                              "feeProtection"
                                            )
                                          }
                                          onBlur={() =>
                                            toggleEdit("feeProtection")
                                          }
                                          autoFocus
                                          className="border border-black text-center"
                                        />
                                      ) : (
                                        <span
                                          onClick={() =>
                                            toggleEdit("feeProtection")
                                          }
                                        >
                                          {fees?.feeProtection.toFixed(2)}
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  {/* Course Fees (Before GST) */}
                                  <tr>
                                    <td className="border-black border pl-2 pt-3">
                                      Course Fees (Before GST)
                                    </td>
                                    <td className="text-center  border-black border">
                                      <span>
                                        {(
                                          fees?.tuitionFee +
                                          fees?.medicalInsurance +
                                          fees?.resourceFee
                                        ).toFixed(2)}
                                      </span>
                                    </td>
                                  </tr>

                                  {/* 9% GST */}
                                  <tr>
                                    <td className="border-black border pl-2 pt-3">
                                      9% GST
                                    </td>
                                    <td className="text-center  border-black border">
                                      {isEditing.gst ? (
                                        <input
                                          type="number"
                                          value={fees?.gst}
                                          onChange={(e) =>
                                            handleInputChangeFees(e, "gst")
                                          }
                                          onBlur={() => toggleEdit("gst")}
                                          autoFocus
                                          className="border border-black text-center"
                                        />
                                      ) : (
                                        <span onClick={() => toggleEdit("gst")}>
                                          {fees?.gst.toFixed(2)}
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  {/* Total Course Fees */}
                                  <tr className=" border-black border">
                                    <td className="border-black border pl-2 font-bold pt-3">
                                      Total Course Fees Payable
                                    </td>
                                    <td className="text-center font-bold border-black border pt-3">
                                      {(
                                        fees?.tuitionFee +
                                        fees?.medicalInsurance +
                                        fees?.resourceFee +
                                        fees?.gst
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                  {/* No. of Instalment */}
                                  <tr>
                                    <td className="border-black font-bold border pl-2 pt-3">
                                      No. of Instalment
                                    </td>
                                    <td className="text-center">
                                      <span className="font-bold">
                                        {
                                          studentContractLetterData?.installment
                                            ?.length
                                        }
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="flex justify-center">
                              <span className="text-center text-[20px]  font-bold mt-10">
                                <u>INSTALMENT SCHEDULE </u>
                              </span>
                            </div>
                            <div className="flex mt-2 justify-center">
                              <table className="text-[14px]">
                                <thead>
                                  <tr className="border border-black">
                                    <th className="border w-[250px] h-14 bg-[#BFBFBF] border-black">
                                      Instalment Schedule
                                    </th>
                                    <th className="border w-[230px] bg-[#BFBFBF] border-black">
                                      Amount (with GST, if any) (S$)
                                    </th>
                                    <th className="border w-[200px] bg-[#BFBFBF] border-black">
                                      Date Due
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="text-center">
                                  {installments.length > 0 ? (
                                    installments?.map((item, index) => (
                                      <tr
                                        key={index}
                                        className="border border-black"
                                      >
                                        <td className="pl-2 border relative top-2 border-black pb-5">
                                          {`${index + 1}st instalment`}
                                        </td>
                                        <td className="text-center border relative top-2 border-black pb-5">
                                          <input
                                            type="number"
                                            value={Math.round(item.amount)}
                                            onChange={(e) =>
                                              handleInputChange(
                                                index,
                                                "amount",
                                                parseFloat(e.target.value)
                                              )
                                            }
                                            className="text-center w-full p-1"
                                          />
                                        </td>
                                        <td className="text-center border  relative top-2 border-black pb-5">
                                          <input
                                            type="date"
                                            value={
                                              new Date(item.date)
                                                .toISOString()
                                                .split("T")[0]
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                index,
                                                "date",
                                                e.target.value
                                              )
                                            }
                                            className="text-center w-full p-1 hide-date-icon print:appearance-none"
                                          />
                                          <div className="w-4 h-4 hidden print:block bg-white absolute right-[8px] bottom-[1.75rem]"></div>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="3"
                                        className="text-center border border-black pb-14"
                                      >
                                        No installment data available
                                      </td>
                                    </tr>
                                  )}
                                  <tr className="border border-black">
                                    <th className="pl-2 pb-2">
                                      Total Course Fees Payable:
                                    </th>
                                    <th className="text-center border-black border pb-2">
                                      {installments
                                        .reduce(
                                          (acc, item) => acc + item.amount,
                                          0
                                        )
                                        .toFixed(2)}
                                    </th>
                                    <td className="text-center bg-[#BFBFBF] pb-2"></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="mt-2 text-[12px]">
                              <span>
                                1. &nbsp;Each instalment amount shall not exceed
                                the following:
                              </span>
                              <ul class="list-disc ml-5 pl-2">
                                <li>
                                  12 months’ worth of fees for EduTrust
                                  certified PEIs*;
                                </li>
                                <li class="line-through">
                                  6 months’ worth of fees for
                                  non-EduTrust-certified PEIs with Industry-Wide
                                  Course Fee Insurance Scheme (IWC)*;
                                </li>
                                <li>
                                  <span className="line-through">
                                    2 months’ worth of fees for
                                    non-EduTrust-certified PEIs without IWC*.
                                  </span>{" "}
                                  <br />*{" "}
                                  <span className="line-through-none">
                                    {" "}
                                    Delete as appropriate by striking through{" "}
                                  </span>
                                </li>
                              </ul>
                              <span className="flex flex-col gap-3 m-2">
                                <span>
                                  2.&nbsp;Each instalment after the first shall
                                  be collected within one week before the next
                                  payment scheduled
                                </span>
                                <span>
                                  3.&nbsp;All fees are in Singapore Dollars and,
                                  where applicable, inclusive of the prevailing
                                  Goods and Services Tax (GST).
                                </span>
                              </span>
                            </div>
                          </div>

                          <div className="text-center ">Page 8 of 12</div>
                        </div>

                        <div>
                          <div className="flex justify-between text-[14px]  pl-12">
                            <span className="font-semibold">
                              <EditableField
                                fieldKey="contractNo"
                                value={values.contractNo}
                                handleChange={handleFieldChange} // Pass the change handler
                              />
                            </span>
                            <span>
                              Standard PEI-Student Contract Version 4.0
                            </span>
                          </div>
                          <div>
                            <div className="flex justify-center items-center">
                              {" "}
                              <span className="text-center text-[20px]  font-bold mt-10">
                                SCHEDULE C<br /> <u>COURSE DETAILS </u>
                              </span>
                            </div>

                            <div className="mt-5 pl-[2.75rem]">
                              <table>
                                <thead>
                                  {/* <tr>
                                    <th className="border bg-[#BFBFBF] border-black w-[600px] text-[13px] ">
                                      {" "}
                                      Type and Purpose of Fees
                                    </th>
                                    <th className="border  bg-[#BFBFBF] w-[200px] pl-2 pr-2 border-black text-[13px] ">
                                      Amount (with GST, if any) (S$)
                                    </th>
                                  </tr> */}
                                </thead>
                                <tbody>
  <tr>
    <td className="border border-black p-2 text-[12px]">Late Payment Fees</td>
    <td className="border border-black p-2 text-center text-[12px]">1% Per Week</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Airport Pickup</td>
    <td className="border border-black p-2 text-center text-[12px]">218.00</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Student’s Pass Medical Check-up (Payable to Medical Clinic)</td>
    <td className="border border-black p-2 text-center text-[12px]">60.00</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">
      (On Renewal) ICA Student Pass Issuance <br />+ ICA Processing Fee (Payable to ICA)
    </td>
    <td className="border border-black p-2 text-center text-[12px]">
      98.10 <br />+ 49.05
    </td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Admin fee for Student Pass renewal</td>
    <td className="border border-black p-2 text-center text-[12px]">109.00</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Assessment Appeal Fee (per module)</td>
    <td className="border border-black p-2 text-center text-[12px]">109.00</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Re-Assessment Fees (per module) 1st attempt</td>
    <td className="border border-black p-2 text-center text-[12px]">109.00</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Re-Assessment Fees (per module) 2nd attempt</td>
    <td className="border border-black p-2 text-center text-[12px]">218.00</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Re-Module Fees (Non WSQ Modules)</td>
    <td className="border border-black p-2 text-center text-[12px]">872.00</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Re-Module Fees (WSQ Modules)</td>
    <td className="border border-black p-2 text-center text-[12px]">
      Prevailing individual module <br /> fees <br /> (ref to the official website)
    </td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Make-up Lesson per 4 hours session</td>
    <td className="border border-black p-2 text-center text-[12px]">109.00</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Deferment Fee</td>
    <td className="border border-black p-2 text-center text-[12px]">109.00</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Kitchen Tool Kit</td>
    <td className="border border-black p-2 text-center text-[12px]">54.50</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Chef Jacket only (per piece) - Additional</td>
    <td className="border border-black p-2 text-center text-[12px]">32.70</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Chef Apron (per piece) - Additional</td>
    <td className="border border-black p-2 text-center text-[12px]">32.70</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Chef Hat (per piece) - Additional</td>
    <td className="border border-black p-2 text-center text-[12px]">32.70</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Course Book (per module) - Additional</td>
    <td className="border border-black p-2 text-center text-[12px]">32.70</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Request Letter and Certifying Letter / Statement</td>
    <td className="border border-black p-2 text-center text-[12px]">54.50</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">
      ‘Certified True Copy’ Request of Diploma Certificate / Transcript
    </td>
    <td className="border border-black p-2 text-center text-[12px]">54.50</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">Re-issuance Request of Diploma Certificate / Transcript</td>
    <td className="border border-black p-2 text-center text-[12px]">54.50</td>
  </tr>
  <tr>
    <td className="border border-black p-2 text-[12px]">WSQ Food Safety Level 1</td>
    <td className="border border-black p-2 text-center text-[12px]">163.50</td>
  </tr>
</tbody>

                              </table>
                            </div>
                            <div className="text-center mt-[142px]">
                              Page 9 of 12
                            </div>
                            <div className="flex justify-between text-[14px]  pl-12">
                              <span className="font-semibold">
                                <EditableField
                                  fieldKey="contractNo"
                                  value={values.contractNo}
                                  handleChange={handleFieldChange} // Pass the change handler
                                />
                              </span>
                              <span>
                                Standard PEI-Student Contract Version 4.0
                              </span>
                            </div>
                          </div>

                          <div>
                            <div>
                              <div className="flex justify-center">
                                {" "}
                                <span className="text-center text-[20px]  font-bold mt-10">
                                  SCHEDULE D<br /> <u>REFUND POLICY </u>
                                </span>
                              </div>
                              <div className="flex justify-center w-full pl-6 mt-10 text-[14px]">
                                <table>
                                  <thead>
                                    <tr>
                                      <th className=" bg-[#BFBFBF] p-2">
                                        {" "}
                                        % of [the amount of Course Fees and
                                        Miscellaneous Fees paid under Schedules
                                        B and C]
                                      </th>
                                      <th className="border border-black bg-[#BFBFBF] p-2 w-[350px]">
                                        If the Contracting Party’s written
                                        notice of withdrawal is received:
                                      </th>
                                    </tr>
                                    <tr>
                                      <td className="border border-black text-center ">
                                        [80%]
                                      </td>
                                      <td className="border border-black p-2  ">
                                        more than [30] working days before the
                                        Course Commencement Date
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-black text-center  ">
                                        [10%]
                                      </td>
                                      <td className="border border-black p-2 ">
                                        on or before, but not more than [30]
                                        working days before the Course
                                        Commencement Date
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-black text-center  ">
                                        [0%]
                                      </td>
                                      <td className="border border-black p-2 ">
                                        {" "}
                                        after, but not more than [7] working
                                        days after the Course Commencement Date
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-black text-center  ">
                                        [0%]
                                      </td>
                                      <td className="border border-black p-2 ">
                                        {" "}
                                        more than [7] working days after the
                                        Course Commencement Date
                                      </td>
                                    </tr>
                                  </thead>
                                </table>
                              </div>
                            </div>
                            <div className="text-center mt-[600px]">
                              Page 10 of 12
                            </div>
                            <div className="flex justify-between text-[14px]  pl-12">
                              <span className="font-semibold">
                                <EditableField
                                  fieldKey="contractNo"
                                  value={values.contractNo}
                                  handleChange={handleFieldChange} // Pass the change handler
                                />
                              </span>
                              <span>
                                Standard PEI-Student Contract Version 4.0
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="relative">
                          <div className="flex justify-center">
                            {" "}
                            <span className="text-center text-[20px]  font-bold mt-10">
                              SCHEDULE E<br /> <u>SECTION 3 </u>
                            </span>
                          </div>
                          <div className="w-full flex flex-col items-center gap-3 justify-center">
                            <div className="font-bold w-[200px]  h-10 text-[17px]  border border-black text-center  mt-4 ">
                              REFUND EVENTS
                            </div>

                            <div className="w-full flex gap-2 justify-center">
                              <div className="w-[150px] text-[13px] p-2 h-[170px] border border-black">
                                Section 3.1(a): <br />
                                PEI cannot commence Course on the Course
                                Commencement Date
                              </div>

                              <div className="w-[150px]  text-[13px] p-2 h-[170px] border border-black">
                                Section 3.1(b): PEI cannot complete the Course
                                on the Course Completion Date
                              </div>

                              <div className="w-[150px]  text-[13px] p-2 h-[170px] border border-black">
                                Section 3.1(c): PEI terminates the Course before
                                the Course Completion Date
                              </div>

                              <div className="w-[150px]  text-[13px] p-2 h-[170px] border border-black">
                                Section 3.1(d): Student does not meet entry or
                                matriculation requirements in Schedule A
                              </div>

                              <div className="w-[150px]  text-[13px] p-2 h-[170px] border border-black">
                                Section 3.1(e): Student’s Student Pass
                                application rejected by the ICA.
                              </div>
                            </div>
                            <div className="gap-24 flex relative bottom-10 ">
                              <span className="text-[50px]">&#8595;</span>
                              <span className="text-[50px]">&#8595;</span>
                              <span className="text-[50px]">&#8595;</span>
                              <span className="text-[50px]">&#8595;</span>
                              <span className="text-[50px]">&#8595;</span>
                            </div>
                            <div className="flex gap-2">
                              <div className=" w-full border  bottom-16 text-[13px] relative border-black h-[100px] p-1">
                                PEI to recommend alternative study arrangements
                              </div>
                              <div className=" p-1 border bottom-16 h-[100px] relative border-black ">
                                Immediate termination of the Contract by the PEI
                                and full refund
                              </div>
                            </div>

                            <div className=" flex relative bottom-24 gap-48  justify-start right-36">
                              <span className="text-[50px]">&#8595;</span>
                              <span className="text-[50px]">&#8595;</span>
                            </div>

                            <div className="w-[75%] p-2 flex relative right-[93px] bottom-32 gap-6">
                              <div className="w-[40%]  p-2 border h-[180px] border-black">
                                PEI recommends alternative study arrangements
                                and these are accepted by the Student
                              </div>
                              <div className="w-[40%] p-2 border border-black">
                                PEI cannot recommend alternative study
                                arrangements or such arrangements are not
                                accepted by the Student
                              </div>
                            </div>
                            <div className=" flex relative bottom-44 gap-48  justify-start right-36">
                              <span className="text-[50px]">&#8595;</span>
                              <span className="text-[50px]">&#8595;</span>
                            </div>
                            <div className="w-[75%] text-[13px] p-2 flex relative right-[93px] bottom-52 gap-6">
                              <div className="w-[40%]  p-2 border h-[250px] flex flex-col gap-3 border-black">
                                <span>
                                  <b>Clause 3.1(a)-</b> Termination of Contract
                                  by Contracting Party and <b> full refund </b>
                                </span>
                                <span>
                                  <b>Clauses 3.1(b) and (c)- </b> Termination of
                                  Contract by Contracting Party{" "}
                                  <b>pro-rata refund</b>
                                </span>
                              </div>
                              <div className="w-[40%] p-2 border flex gap-3 flex-col border-black">
                                <span>
                                  <b>Clause 3.1(a)- </b> Automatic termination
                                  of this Contract and <b> full refund.</b> New
                                  contract to be signed
                                </span>
                                <span>
                                  <b>Clauses 3.1(b) and (c)- </b>Automatic
                                  termination of this Contract and{" "}
                                  <b>pro-rata refund.</b> New contract to be
                                  signed
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="text-center absolute top-[1030px]  w-full  ">
                            Page 11 of 12
                          </div>
                          <div className="flex justify-between relative bottom-[7.25rem] text-[14px]  pl-12">
                            <span className="font-semibold">
                              <EditableField
                                fieldKey="contractNo"
                                value={values.contractNo}
                                handleChange={handleFieldChange} // Pass the change handler
                              />
                            </span>
                            <span>
                              Standard PEI-Student Contract Version 4.0
                            </span>
                          </div>
                        </div>

                        <div>
                          <div className=" flex flex-col gap-20">
                            <div className="flex  flex-col gap-7 font-[13px]">
                              <hr
                                className="w-full    bg-black"
                                style={{ height: "2px" }}
                              />
                              <span className="text-[12px] ">
                                The parties hereby acknowledge and agree to the
                                terms stated in this Contract.
                              </span>
                            </div>
                            <div>
                              <span className="text-[14px] ">
                                SIGNED by the PEI
                              </span>
                            </div>

                            <div className="flex flex-col gap-20">
                              <div className="flex text-[15px]   flex-col ">
                                <hr
                                  className="bg-black w-[250px]"
                                  style={{ height: "2px" }}
                                />
                                <span className="text-[13px]">
                                  {" "}
                                  Authorised Signatory of the PEI{" "}
                                </span>
                                <span>
                                  Name:{" "}
                                  <span className="font-bold">
                                    {" "}
                                    SUDEEP SREEDHARAN{" "}
                                  </span>
                                </span>
                                <span className="text-[13px] flex">
                                  Date:{" "}
                                  <EditableField
                                    fieldKey="dateField"
                                    value={values.dateField}
                                    handleChange={handleFieldChange}
                                    isDateField={true} // Indicate this field is a date
                                  />
                                </span>
                              </div>

                              <div>SIGNED by the Contracting Party </div>
                              <div className="flex flex-col text-[15px]">
                                <hr
                                  className="bg-black w-[250px]"
                                  style={{ height: "2px" }}
                                />
                                <span className="text-[13px]">
                                  {" "}
                                  Name of Contracting Party{" "}
                                </span>
                                <span>
                                  Name:{" "}
                                  <span className="font-bold uppercase">
                                    {" "}
                                    {studentContractLetterData.name}{" "}
                                  </span>
                                </span>
                                <span className="text-[13px]">Date: </span>
                              </div>
                            </div>
                          </div>
                          <div className="text-center mt-[360px] ">
                            Page 12 of 12
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>Fetching.....</p>
      )}
    </>
  );
};

export default StudentPreContract;
