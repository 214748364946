import React, { useEffect, useState } from "react";
import {
  AddStudentAPI,
  fetchAgentNamesAPI,
  // fetchCountriesAPI,
  fetchCourseOptionAPI,
  fetchRepNamesAPI,
} from "../../../API/admin/AdminAPI";
import Loader from "./Loader";

const AddStude = ({
  setCommissionData,
  setQuicKCommissionModal,
  setAddStudentModal,
  collegeData,
  setStudents,
  students,
}) => {
  const [countries, setCountries] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [repData, setRepData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [studentExists, setStudentExists] = useState(false);
  const [tenureInputs, setTenureInputs] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [filteredCourses, setFilteredCourses] = useState([]); // To store the filtered course options

  // const [isTenureLimit, setIsTenureLimit] = useState(false);
  const [courseTypeOptions] = useState([
    { label: " Diploma", value: "diploma" },
    { label: "Higher Diploma", value: "higher_diploma" },
    { label: "Post Graduation", value: "post_graduation" },
  ]);
  const [formData, setFormData] = useState({
    salutation: "",
    fullName: "",
    email: "",
    age: "",
    // phone: "",
    passport: "",
    representative: "",
    college: collegeData?._id,
    course: "",
    courseType: "",
    agents: "",
    intake: "",
    installment: "",
    year: "",
    tenure: "1-time",
    tenureDate: [],
    intakeDate: "",
    guardianName: "",
    guardianPassport: "",
    finalExamResultDate: "",
    confermentDate: "",

    // addressLine1: "",
    // addressLine2: "",
    // city: "none",
    // state: "none",
    address:"",
    country: "none",
    // zip: "",
  });
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "tenureDate") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: [...prevState[name], value],
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleTenureDateChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => {
      const updatedTenureDate = [...prevState.tenureDate];
      const existingIndex = updatedTenureDate.findIndex((item) => item[name]);

      if (existingIndex !== -1) {
        updatedTenureDate[existingIndex][name] = value;
      } else {
        updatedTenureDate.push({ [name]: value });
      }

      return {
        ...prevState,
        tenureDate: updatedTenureDate,
      };
    });
  };

  const handleInstallmentInputChange = (event) => {
    const { name, value } = event.target;
    if (value === "one-time-settlement") {
      formData.tenure = "1-time";
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(formData.email);
    setIsEmailValid(isValidEmail);
  };
  // const handelTenureInput = (event) => {
  //   const { name, value } = event.target;
  //   if (value >= 12 || value === "e") {
  //     // setIsTenureLimit(true);
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [name]: ${12}-times,
  //     }));
  //   } else if (value < 2) {
  //     // setIsTenureLimit(true);
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [name]: ${2}-times,
  //     }));
  //   } else {
  //     // setIsTenureLimit(false);
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [name]: ${value}-times,
  //     }));
  //   }
  // };
  useEffect(() => {
    if (formData.courseType) {
      const filtered = courseOptions?.filter(
        (course) => course?.courseType === formData.courseType
      );
      console.log(courseOptions);

      setFilteredCourses(filtered);
    } else {
      setFilteredCourses(courseOptions);
    }
  }, [formData.courseType, courseOptions]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (isEmailValid && Object.keys(errors).length === 0) {
      try {
        const response = await AddStudentAPI(formData);
        setIsLoader(true);
        if (response.status === 201) {
          setIsLoader(false);
          // Reset form after successful submission
          setFormData({
            salutation: "",
            fullName: "",
            email: "",
            age: "",
            // phone: "",
            passport: "",
            representative: "",
            college: collegeData?._id,
            course: "",
            agents: "",
            installment: "",
            intake: "",
            intakeDate: "",
            tenure: "1-time",
            year: "",
            tenureDate: [],
            expires: "",
            // addressLine1: "",
            // addressLine2: "",
            guardianName: "",
            guardianPassport: "",
            finalExamResultDate: "",
            confermentDate: "",
         
            address:"",
            country: "",
            // zip: "",
          });
          setStudents([...students, response?.data?.student]);
          setCommissionData(response?.data?.paymentResponse);
          setQuicKCommissionModal(true);
        } else if (response.status === 409) {
          setIsLoader(false);
          setStudentExists(true);
        }
      } catch (error) {
        setIsLoader(false);
        console.log(error);
      }
    } else {
      setFormErrors(errors);
    }
  };
  const validateForm = () => {
    const errors = {};
    // Validate each input here
    if (!formData.salutation) {
      errors.salutation = "Salutation is required";
    }
    if (formData.fullName.trim() === "") {
      errors.fullName = "Full Name is required";
    }
    // if (formData.email.trim() === "") {
    //   errors.email = "Email is required";
    // }
    // if (formData.phone.trim() === "") {
    //   errors.phone = "Phone is required";
    // }
    if (formData.age.trim() === "") {
      errors.age = "age is required";
    }
    if (formData.passport.trim() === "") {
      errors.passport = "Passport is required";
    }
    if (formData.age && formData.age < 18) {
      if (formData.guardianName?.trim() === "") {
        errors.guardianName = "Guardian Name is required";
      }
      if (formData.guardianPassport?.trim() === "") {
        errors.guardianPassport = "Guardian Passport is required";
      }
    }
    if (formData.finalExamResultDate.trim() === "") {
      errors.finalExamResultDate = "finalExamResultDate is required";
    }
    if (formData.confermentDate.trim() === "") {
      errors.confermentDate = "confermentDate is required";
    }
    if (formData.representative.trim() === "") {
      errors.representative = "Representative is required";
    }
    if (formData.agents.trim() === "") {
      errors.agents = "Agents is required";
    }
    if (formData.intake.trim() === "") {
      errors.intake = "Intake is required";
    }
    if (formData.intakeDate.trim() === "") {
      errors.intakeDate = "intakeDate is required";
    }
    if (formData.year.trim() === "") {
      errors.year = "year is required";
    }
    if (formData.installment.trim() === "") {
      errors.installment = "Mode is required";
    }
    if (
      formData.installment === "installment" &&
      formData.tenure.trim() === "1-time"
    ) {
      errors.tenure = "Tenure is required";
    }
    if (formData.tenureDate.length !== Number(formData.tenure.split("-")[0])) {
      errors.tenureDate = "due date is required";
    }
    // if (formData.addressLine1.trim() === "") {
    //   errors.addressLine1 = "Address is required";
    // }
    // if (formData.addressLine2.trim() === "") {
    //   errors.addressLine2 = "Address Line2 is required";
    // }
    if (formData.address.trim() === "") {
      errors.address = "Address is required"
    }
    if (formData.country.trim() === "") {
      errors.country = "Country is required";
    }
    // if (formData.zip.trim() === "") {
    //   errors.zip = "Zip is required";
    // }
    return errors;
  };

  // const fetchCountries = async () => {
  //   const country = await fetchCountriesAPI();
  //   setCountries(country);
  // };
  const fetchAgentNameOptions = async (repId) => {
    try {
      const response = await fetchAgentNamesAPI(repId, collegeData._id);
      if (response?.status === 200) {
        setAgentOptions(response?.data);
      } else {
        setAgentOptions([]);
      }
    } catch (error) {
      setAgentOptions([]);
    }
  };
  const fetchRepData = async () => {
    try {
      const response = await fetchRepNamesAPI(collegeData._id);
      if (response?.status === 200) {
        setRepData(response?.data);
      } else {
        setRepData([]);
      }
    } catch (error) {
      setRepData([]);
    }
  };
  const fetchCourseNameOptions = async () => {
    try {
      const courseNames = await fetchCourseOptionAPI(collegeData._id);
      if (courseNames.status === 200) {
        setCourseOptions(courseNames.data);
      } else {
        setCourseOptions([]);
      }
    } catch (error) {
      setCourseOptions([]);
    }
  };
  useEffect(() => {
    if (formData.representative !== "") {
      fetchAgentNameOptions(formData.representative);
    }
  }, [formData.representative]);

  useEffect(() => {
    fetchCourseNameOptions();
    // fetchCountries();
    fetchRepData();
  }, []);

  useEffect(() => {
    // Update the total amount and tenure when payment prop changes
    if (formData.tenure !== "") {
      // Generate an array of checkbox values based on the tenure
      const values = Array.from(
        { length: formData.tenure.split("-")[0] },
        () => false
      );
      setTenureInputs(values);
    }
  }, [formData.tenure]);
  return (
    <div className="flex justify-center items-center md:flex py-[20px]">
      <div className=" w-full py-[10px] rounded-2xl shadow-lg shadow-gray-500">
        <div className="w-[95%] mx-auto">
          {/* heading */}
          <div className=" text-center md:py-[20px] text-[30px] md:text-left flex py-1">
            <p className="font-bold border-b-2 border-gray-400">Add Student</p>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 justify-between">
            <div className="flex gap-2">
              <div className="">
                {/* salutation */}

                <label
                  className="block mb-2 font-medium text-sm"
                  htmlFor="salutation"
                >
                  Salutation:
                </label>
                <select
                  className="block  px-4 py-2 border border-gray-300 rounded-lg"
                  id="salutation"
                  name="salutation"
                  value={formData.salutation}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                </select>
                {formErrors.salutation && (
                  <span className="text-xs text-red-600">
                    {formErrors.salutation}
                  </span>
                )}
              </div>
              {/* name */}
              <div className="w-full">
                <label
                  className="block mb-2 font-medium text-sm"
                  htmlFor="fullName"
                >
                  Full Name:
                </label>
                <input
                  className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder="Full Name"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.fullName && (
                  <span className="text-xs text-red-600">
                    {formErrors.fullName}
                  </span>
                )}
              </div>
            </div>
            {/* phone */}
            {/* <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="contactNumber"
              >
                Contact Number:
              </label>
              <input
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="tel"
                id="contactNumber"
                name="phone"
                placeholder="Contact Number"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
              {formErrors.phone && (
                <span className="text-xs text-red-600">{formErrors.phone}</span>
              )}
            </div> */}
            {/* mail id */}
            {/* <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="emailAddress"
              >
                Email Address:
              </label>
              <input
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={validateEmail}
                required
              />
              {studentExists && (
                <span className="text-xs text-red-600">
                  Email already exist !
                </span>
              )}
              {formErrors.email && (
                <span className="text-xs text-red-600">{formErrors.email}</span>
              )}
            </div> */}
            <div className="">
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="passport"
              >
                Passport:
              </label>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="passport"
                name="passport"
                placeholder="Passport"
                value={formData.passport}
                onChange={handleInputChange}
                required
              />
              {formErrors.passport && (
                <span className="text-xs text-red-600">
                  {formErrors.passport}
                </span>
              )}
            </div>
            <div>
              {/* college */}
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="courses"
              >
                College
              </label>
              <select
                className="block w-full px-4 py-2 border border-gray-300 rounded-lg"
                id="colleges"
                name="colleges"
                value={formData.qualification}
                onChange={handleInputChange}
                required
              >
                <option className="text-black" value={collegeData._id} selected>
                  {collegeData.name}
                </option>
              </select>
            </div>
          </div>
          <div className="grid gap-3 grid-cols-1 md:grid-cols-3 my-3">
          <div className="">
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="salutation"
              >
                Age:
              </label>
              <input
                type="number"
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                id="age"
                name="age"
                value={formData.age}
                onChange={handleInputChange}
                required
              />
              {formErrors.age && (
                <span className="text-xs text-red-600">{formErrors.age}</span>
              )}
            </div>
            {formData.age && formData.age < 18 && (
  <>
    <div className="">
      <label className="block mb-2 font-medium text-sm" htmlFor="guardianName">
        Guardian Name:
      </label>
      <input
        className="block w-full px-4 py-2 border placeholder:text-gray-500 border-gray-300 rounded-lg"
        type="text"
        id="guardianName"
        name="guardianName"
        placeholder="Guardian Name"
        value={formData.guardianName}
        onChange={handleInputChange}
        required
      />
      {formErrors.guardianName && (
        <span className="text-xs text-red-600">{formErrors.guardianName}</span>
      )}
    </div>

    <div className="">
      <label className="block mb-2 font-medium text-sm" htmlFor="guardianPassport">
        Guardian Passport:
      </label>
      <input
        className="block w-full px-4 py-2 border placeholder:text-gray-500 border-gray-300 rounded-lg"
        type="text"
        id="guardianPassport"
        name="guardianPassport"
        placeholder="Guardian Passport"
        value={formData.guardianPassport}
        onChange={handleInputChange}
        required
      />
      {formErrors.guardianPassport && (
        <span className="text-xs text-red-600">{formErrors.guardianPassport}</span>
      )}
    </div>
  </>
)}

          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 justify-between my-2">
            {/* Existing course  Type dropdown */}
            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="courseType"
              >
                Course Type:
              </label>
              <select
                className="block w-full px-4 py-2 border text-gray-500 border-gray-300 rounded-lg"
                id="courseType"
                name="courseType"
                value={formData.courseType}
                onChange={handleInputChange}
                required
              >
                <option value="" className="text-black">
                  Select Course Type
                </option>
                {courseTypeOptions.map((type) => (
                  <option
                    className="text-black"
                    key={type.value}
                    value={type.value}
                  >
                    {type.label}
                  </option>
                ))}
              </select>
              {formErrors.courseType && (
                <span className="text-xs text-red-600">
                  {formErrors.courseType}
                </span>
              )}
            </div>
            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="course"
              >
                Course:
              </label>
              <select
                className="block w-full px-4 py-2 border text-gray-500 border-gray-300 rounded-lg"
                id="course"
                name="course"
                value={formData.course}
                onChange={handleInputChange}
                required
              >
                <option value="" className="text-black">
                  Select a Course
                </option>
                {filteredCourses.length !== 0 ? (
                  filteredCourses?.map((courses) => (
                    <option
                      className="text-black"
                      key={courses?._id}
                      value={courses?._id}
                    >
                      {courses?.course}
                    </option>
                  ))
                ) : (
                  <option value="" className="text-black">
                    No courses available
                  </option>
                )}
              </select>
              {formErrors.course && (
                <span className="text-xs text-red-600">
                  {formErrors.course}
                </span>
              )}
            </div>
            {/* finalExamResultDate */}

            <div className="">
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="finalExamResultDate"
              >
                Final Exam Result Date:
              </label>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="date"
                id="finalExamResultDate"
                name="finalExamResultDate"
                placeholder="Final Exam Result Date"
                value={formData.finalExamResultDate}
                onChange={handleInputChange}
                required
              />
              {formErrors.finalExamResultDate && (
                <span className="text-xs text-red-600">
                  {formErrors.finalExamResultDate}
                </span>
              )}
            </div>
            {/* confermentDate */}
            <div className="">
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="finalExamResultDate"
              >
                Conferment Date:
              </label>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="date"
                id="confermentDate"
                name="confermentDate"
                placeholder="Conferment Date"
                value={formData.confermentDate}
                onChange={handleInputChange}
                required
              />
              {formErrors.confermentDate && (
                <span className="text-xs text-red-600">
                  {formErrors.confermentDate}
                </span>
              )}
            </div>
          </div>
          {/* second section */}
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 justify-between my-2">
            <div>
              <label className="block mb-2 font-medium text-sm" htmlFor="year">
                year
              </label>
              <select
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                id="year"
                name="year"
                value={formData.year}
                onChange={handleInputChange}
                required
              >
                <option selected>select a year</option>
                <option className="text-black" value="2024">
                  2024
                </option>
                <option className="text-black" value="2025">
                  2025
                </option>
                <option className="text-black" value="2026">
                  2026
                </option>
                <option className="text-black" value="2027">
                  2027
                </option>
              </select>
              {formErrors.year && (
                <span className="text-xs text-red-600">{formErrors.year}</span>
              )}
            </div>

            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="Intake"
              >
                Intake:
              </label>
              <select
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                id="intake"
                name="intake"
                value={formData.intake}
                onChange={handleInputChange}
                required
              >
                <option selected>select a month</option>
                <option className="text-black" value="Jan">
                  Jan
                </option>
                <option className="text-black" value="Feb">
                  Feb
                </option>
                <option className="text-black" value="Mar">
                  Mar
                </option>
                <option className="text-black" value="Apr">
                  Apr
                </option>
                <option className="text-black" value="May">
                  May
                </option>
                <option className="text-black" value="Jun">
                  Jun
                </option>
                <option className="text-black" value="Jul">
                  Jul
                </option>
                <option className="text-black" value="Aug">
                  Aug
                </option>
                <option className="text-black" value="Sep">
                  Sep
                </option>
                <option className="text-black" value="Oct">
                  Oct
                </option>
                <option className="text-black" value="Nov">
                  Nov
                </option>
                <option className="text-black" value="Dec">
                  Dec
                </option>
              </select>
              {formErrors.intake && (
                <span className="text-xs text-red-600">
                  {formErrors.intake}
                </span>
              )}
            </div>

            {/* intake Date */}
            <div>
              <label className="block mb-2 font-medium text-sm" htmlFor="year">
                Intake Date
              </label>

              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="date"
                id="intakeDate"
                name="intakeDate"
                placeholder="Intake Date"
                value={formData.intakeDate}
                onChange={handleInputChange}
                required
              />

              {formErrors.intakeDate && (
                <span className="text-xs text-red-600">
                  {formErrors.intakeDate}
                </span>
              )}
            </div>
            <div>
              {/* college */}
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="Installment"
              >
                Payment:
              </label>
              <select
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                id="installment"
                name="installment"
                value={formData.installment}
                onChange={handleInstallmentInputChange}
                required
              >
                <option selected>Select payment mode</option>
                <option className="text-black" value="one-time-settlement">
                  One time settlement
                </option>
                <option className="text-black" value="installment">
                  Installment
                </option>
                {/* Add more options as needed */}
              </select>
              {formErrors.installment && (
                <span className="text-xs text-red-600">
                  {formErrors.installment}
                </span>
              )}
            </div>
            {formData.installment === "installment" && (
              <div>
                <label
                  className="block mb-2 font-medium text-sm"
                  htmlFor="tenure"
                >
                  Payment Tenure:
                </label>
                {/* <input
                  className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  id="tenure"
                  name="tenure"
                  type="number"
                  value={Number(formData.tenure.split("-")[0])}
                  onChange={handelTenureInput}
                  placeholder="Times"
                  required
                ></input> */}
                <select
                  className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  id="tenure"
                  name="tenure"
                  value={formData.tenure}
                  onChange={handleInputChange}
                  required
                >
                  <option selected>Select times</option>
                  <option className="text-black" value="2-Time">
                    2 Times
                  </option>
                  <option className="text-black" value="3-Time">
                    3 Times
                  </option>
                  <option className="text-black" value="4-Times">
                    4 Times
                  </option>
                  <option className="text-black" value="5-Times">
                    5 Times
                  </option>
                  <option className="text-black" value="6-Times">
                    6 Times
                  </option>
              
                </select>

                {formErrors.tenure && (
                  <span className="text-xs text-red-600">
                    {formErrors.tenure}
                  </span>
                )}
                {/* <span className="text-xs text-black">2-12 (min-max)</span> */}
              </div>
            )}
          </div>
          <div>
            {/* <label htmlFor="">Expiration date</label> */}
            <div className="grid md:grid-cols-4 lg:grid-cols-6 grid-cols-2 gap-3 bg-gray-100 p-2 rounded-lg my-2">
              {tenureInputs.length !== 0
                ? tenureInputs.map((input, index) => (
                    <>
                      <div>
                        <p className="p-1 text-xs">
                          {tenureInputs.length === 1
                            ? "Due Date:"
                            : `Emi-${index + 1} Due Date`}
                        </p>
                        <input
                          className="block px-2 py-1 border border-gray-300 rounded-lg text-sm"
                          id="tenureDate"
                          name={`tenureDate-${index + 1}`}
                          // value={formData.installment}
                          onChange={handleTenureDateChange}
                          required
                          type="date"
                        />
                      </div>
                    </>
                  ))
                : null}
            </div>
          </div>
          {formErrors.tenureDate && (
            <div className="py-2 w-full flex justify-center">
              <span className="text-xs text-red-600 w-full text-center">
                {formErrors.tenureDate}
              </span>
            </div>
          )}
          {/* fourth section */}
          <div className="grid gap-3 grid-cols-1 md:grid-cols-2 my-3">
            <div>
              {/* college */}
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="representative"
              >
                Representative:
              </label>
              <select
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                id="representative"
                name="representative"
                value={formData.representative}
                onChange={handleInputChange}
                required
              >
                <option className="text-black" value={""} selected>
                  select a rep
                </option>
                <option className="text-black" value={"none"}>
                  None
                </option>
                {repData.length > 0 &&
                  repData.map((rep) => (
                    <option className="text-black" value={rep._id}>
                      {rep.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="Agents"
              >
                Agents:
              </label>
              <select
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                id="agents"
                name="agents"
                value={formData.agents}
                onChange={handleInputChange}
                required
              >
                <option className="text-black" selected>
                  Select a Agent
                </option>
                {agentOptions.length !== 0 &&
                  agentOptions.map((agent) => (
                    <option
                      className="text-black"
                      key={agent?._id}
                      value={agent?._id}
                    >
                      {agent?.name}
                    </option>
                  ))}
              </select>
              {formErrors.agents && (
                <span className="text-xs text-red-600">
                  {formErrors.agents}
                </span>
              )}
            </div>
          </div>
          {/* section five */}
          <p className="block my-3 font-medium text-sm" htmlFor="Address">
            Address:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 my-[10px]">
  <div className="col-span-2">
    <input
      className="block w-full px-4 py-2 border placeholder:text-gray-500 border-gray-300 rounded-lg"
      type="text"
      id="address"
      name="address"
      placeholder="Address"
      value={formData.address}
      onChange={handleInputChange}
      required
    />
    {formErrors.address && (
      <span className="text-xs text-red-600">{formErrors.address}</span>
    )}
  </div>

  <div>
    <input
      className="block w-full px-4 py-2 border placeholder:text-gray-500 border-gray-300 rounded-lg"
      type="text"
      id="country"
      name="country"
      placeholder="Country"
      onChange={handleInputChange}
      required
    />
    {formErrors.country && (
      <span className="text-xs text-red-600">{formErrors.country}</span>
    )}
  </div>
</div>


          <div className="flex justify-end gap-x-[20px]">
            <button
              onClick={() => setAddStudentModal(false)}
              className="py-[8px] md:ease-in duration-300 mt-4 bg-black hover:bg-red-600  text-sm text-white font-bold px-2  rounded md:w-40"
              type="submit"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className=" ease-in duration-300 mt-4 bg-red-500 hover:bg-green-600  text-sm text-white font-bold  py-2 px-3  rounded md:w-40"
              type="submit"
              disabled={isLoader} 
            >
              {!isLoader ? <span>Save</span> : <Loader />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStude;
