import React, { useState, useEffect } from "react";
import HomeIcon from "@mui/icons-material/Home";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import FlagIcon from "@mui/icons-material/Flag";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import StudentDocs from "../StudentDocGeneration/StudentDocs";
import EditStudent from "../EditForms.jsx/EditStudent";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const StudentProfile = ({ studentData, commission, handleRefresh }) => {
  const [commissionData, setCommissionData] = useState({});
  const [editStudentModal, setEditStudentModal] = useState(false);
  const [status, setStatus] = useState("pending"); // Initial status
  const [openDialog, setOpenDialog] = useState(false); // State for dialog
  const [newStatus, setNewStatus] = useState(""); // Temporary status holder
  
  useEffect(() => {
    setCommissionData(commission);
  }, [commission]);

  // Open the dialog to confirm status change
  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setNewStatus(newStatus); // Store the new status temporarily
      setOpenDialog(true); // Open the confirmation dialog
    }
  };

  // Handle confirmation dialog close
  const handleCloseDialog = (confirmed) => {
    if (confirmed) {
      setStatus(newStatus); // If confirmed, apply the new status
    }
    setOpenDialog(false); // Close the dialog
  };

  return (
    <>
      <div className="relative">
        <div>
          <div
            className={`flex justify-between gap-x-[80px] sm:gap-x-[70px] md:gap-x-[800px] ${
              editStudentModal && `blur-sm`
            }`}
          >
            <div>
              <h4 className="font-bold">Student</h4>
            </div>
            <div className="md:flex gap-2">
              <div
                onClick={() => setEditStudentModal(true)}
                className="flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 hover:bg-black transition group"
              >
                <BorderColorRoundedIcon
                  sx={{ fontSize: 25 }}
                  className="text-black-600 group-hover:text-white transition"
                />
              </div>
              {/* <div className="flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 hover:bg-red-600 transition group">
                <DeleteRoundedIcon
                  sx={{ fontSize: 25 }}
                  className="text-black-600 group-hover:text-white transition"
                />
              </div> */}
            </div>
          </div>

          {/* Student information section */}
          <div>
            <div className="flex gap-x-[10px] pb-[15px] items-center">
              <div className="text-[20px]">
                <HomeIcon />
              </div>
              <div>
                <p className="text-gray-800">
                  {studentData?.address?.address},{studentData?.address?.country}
                </p>
              </div>
            </div>
            <div className="flex gap-x-[10px] pb-[15px] items-center">
              <div className="text-[20px]">
                <FlagIcon />
              </div>
              <div>
                <p className="text-gray-800">{studentData?.address?.country}</p>
              </div>
            </div>
            <div className="flex gap-x-[15px]">
              {/* <div className="flex items-center">
                <div className="text-[20px]">
                  <AttachEmailIcon />
                </div>
                <div>
                  <p className="text-gray-800 px-2">{studentData?.email}</p>
                </div>
              </div> */}
            </div>
          </div>

          {/* Toggle button for status */}
          {/* <div className="mt-5">
            <p className="text-gray-400 text-[15px]">Status</p>
            <ToggleButtonGroup
              color="primary"
              value={status}
              exclusive
              onChange={handleStatusChange}
              aria-label="Student Status"
            >
              <ToggleButton
                value="pending"
                style={{
                  backgroundColor: status === "pending" ? "#f0ad4e" : "",
                  color: status === "pending" ? "white" : "black",
                }}
              >
                Pending
              </ToggleButton>
              <ToggleButton
                value="approved"
                style={{
                  backgroundColor: status === "approved" ? "#5cb85c" : "",
                  color: status === "approved" ? "white" : "black",
                }}
              >
                Approved
              </ToggleButton>
            </ToggleButtonGroup>
          </div> */}

          <div className="flex gap-x-[150px] flex-col md:flex-row mt-5">
            <div>
              <div className="pb-[10px]">
                <p className="text-[15px] text-gray-400">Representative</p>
                {studentData?.representative ? (
                  <p className="font-semibold text-[17px] capitalize">
                    {studentData?.representative?.name}
                  </p>
                ) : (
                  <p className="font-semibold text-[14px] capitalize text-red-600">
                    No Representatives
                  </p>
                )}
              </div>
              <div className="pb-[10px]">
                <p className="text-[15px] text-gray-400">Agent</p>
                <p className="font-semibold text-[17px] capitalize">
                  {studentData?.agent?.name}
                </p>
              </div>
            </div>
            <div className="border-l-2 border-gray-400 hidden md:block" />
            <div>
              <div className="pb-[10px]">
                <p className="text-[15px] text-gray-400">College</p>
                <p className="font-semibold text-[17px] capitalize">
                  {studentData?.college?.name}
                </p>
              </div>
              <div className="pb-[10px]">
                <p className="text-[15px] text-gray-400 capitalize">Course</p>
                <p className="font-semibold text-[17px] capitalize">
                  {studentData?.course?.name}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Student document section */}
        <div>
          <StudentDocs studentData={studentData} />
        </div>

        {editStudentModal && (
          <div className="absolute top-0 z-10 w-full">
            <EditStudent
              collegeData={studentData.college}
              setEditStudentModal={setEditStudentModal}
              student={studentData}
              handleRefresh={handleRefresh}
            />
          </div>
        )}

        {/* Confirmation Dialog */}
        <Dialog
          open={openDialog}
          onClose={() => handleCloseDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Status Change"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to change the student's status to{" "}
              <strong>{newStatus}</strong>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleCloseDialog(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={() => handleCloseDialog(true)} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default StudentProfile;
